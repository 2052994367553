import React from "react";
import { useSelector } from "react-redux";
import {
  selectAssetQueryArgs,
  selectSelectedAssetIds,
  setSelectedAssetIds,
} from "store/slices/assetSlice";
import { useAppDispatch } from "store/store";
import { Asset } from "store/models/Asset";
import StyledDataGrid, { StyledDataGridProps } from "components/data-grid/styled-data-grid";
import { GRID_ACTIONS_COLUMN_TYPE, GRID_CHECKBOX_SELECTION_FIELD, GridRowParams, GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { Card } from "@mui/material";
import useAssetsTableColumns from "./useStyledAssetsTableColumns";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { selectBranches, selectYards } from "store/slices/optionSlice";
import { oneMinute } from "utils/timeValues";
import { useGetAllAssetsQuery } from "store/services/asset";
import usePinnedRows from "hooks/usePinnedRows";

interface Props extends Partial<StyledDataGridProps> {
  onAssetTableRowClick?: Function;
  tableActions?: JSX.Element[];
  assets?: Asset[];
  initialState?: GridInitialStatePro;
  tableId: string;
  selectRowOnRowClick?: boolean;
  onRowClick?: (params: GridRowParams<Asset>) => void;
  onRowSelectionModelChange?: (params: GridRowSelectionModel) => void;
  rowSelectionModel?: GridRowSelectionModel;
}

const StyledAssetsTable: React.FC<Props> = ({
  tableActions,
  assets: defaultAssets,
  initialState,
  tableId,
  onRowClick,
  onRowSelectionModelChange,
  rowSelectionModel,
  selectRowOnRowClick,
  onAssetTableRowClick,
  ...otherProps

}) => {
  const assetQueryArgs = useSelector(selectAssetQueryArgs);
  const {data: assets = [], isLoading} = useGetAllAssetsQuery(assetQueryArgs, {pollingInterval: oneMinute * 5});

  const selectedAssetIds = useSelector(selectSelectedAssetIds);
  const dispatch = useAppDispatch();
  const rowData = defaultAssets ? Object.values(defaultAssets) : Object.values(assets)
  const branches = useSelector(selectBranches);
  const yards = useSelector(selectYards);
  const {pinnedRowsIds, setPinnedRowsIds, getPinnedRowData} = usePinnedRows({tableId});

  const assetColumns = useAssetsTableColumns({pinnedRowsIds, setPinnedRowsIds});

  const populatedRows = rowData.map((row) => {
    const branch = branches[row.branch];
    const yard = yards[row.yard]
    return {
      ...row,
      branch: branch?.name,
      yard: yard?.name,
    };
  })

  const {rows, pinnedRows} = getPinnedRowData(populatedRows);

  const handleRowClick = (params: GridRowParams<Asset>) => {
    if (onRowClick) {
      onRowClick(params);
    } else {
      if (selectRowOnRowClick) {
        dispatch(
          setSelectedAssetIds([
            ...Object.values(selectedAssetIds).map((id) => String(id)),
            params.row._id,
          ])
        );
      }
      onAssetTableRowClick && onAssetTableRowClick(params.row._id);
    }
  }

  const handleRowSelectionModelChange = (selectedAssetIds: GridRowSelectionModel) => {
    if (onRowSelectionModelChange) {
      onRowSelectionModelChange(selectedAssetIds)
    } else {
      dispatch(
        setSelectedAssetIds(
          Object.values(selectedAssetIds).map((id) => String(id))
        )
      );
    }
  }

  return (
    <Card sx={{ px: 2 }}>
      <StyledDataGrid
        rows={rows}
        pinnedRows={pinnedRows}
        checkboxSelection
        disableRowSelectionOnClick
        tableId={tableId}
        getRowId={(row: Asset) => row._id}
        onRowClick={handleRowClick}
        columns={assetColumns}
        onRowSelectionModelChange={handleRowSelectionModelChange}
        additionalActions={tableActions}
        rowSelectionModel={
          rowSelectionModel
            ? rowSelectionModel
            : (selectedAssetIds as GridRowSelectionModel)
        }
        initialState={{
          pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_FIELD, GRID_ACTIONS_COLUMN_TYPE],
          },
          sorting: {
            sortModel: [{field: 'serialNumber', sort: 'asc'}],
          },

          ...initialState,
        }}
        loading={isLoading}
        disableFontSizeSelector
        persistColumnOrder
        persistPinnedColumns
        persistFilter
        persistSort
        pageSizeOptions={[10, 15, 25, 50, 100, 250, 500]}
        sx={{ maxHeight: "75vh" }}
        csvOptions={{fileName: `Starfleet Assets`}}
        {...otherProps}
      />
    </Card>
  );
};
export default StyledAssetsTable;
