import React, {
  Dispatch,
  FC,
  SetStateAction,
  useState,
} from "react";
import { Dialog } from "@mui/material";
import { AssetStatus } from "store/models/AssetStatus";
import { titleCase } from "utils/util";
import { LoadingButton } from "@mui/lab";
import { Asset } from "store/models/Asset";
import StyledAssetsTable from "features/assets/table/StyledAssetsTable";
import {
  GridColumnsInitialState,
  GridFilterModel,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import { useGetAllAssetsQuery } from "store/services/asset";

export type AddAssetModalMode = "swap" | "add" | "hidden";

export interface AssetTableFilters extends Record<string, string[]> {
  yard: string[];
  sizeCode: string[];
  status: AssetStatus[];
}

interface Props {
  mode: AddAssetModalMode;
  setMode: Dispatch<SetStateAction<AddAssetModalMode>>;
  isSubmitting: boolean;
  filterModel?: GridFilterModel;
  currentAssets?: Asset[];
  enableSingleRowSelection?: boolean;
  handleAssetSubmit: (
    toggleAllRowsSelected: (set?: boolean | undefined) => void,
    selectedRows: Asset[]
  ) => void;
}

const AddAssetModal: FC<Props> = ({
  setMode,
  mode,
  filterModel = {
    items: [{ field: "status", operator: "in-status", value: ["AVAILABLE"] }],
  },
  isSubmitting,
  handleAssetSubmit,
  enableSingleRowSelection = true,
  currentAssets = [],
}) => {
  const [selectedAssets, setSelectedAssets] = useState<Asset[]>([]);
  const {data: storeAssets = []} = useGetAllAssetsQuery({statuses: ["AVAILABLE"]});

  const assetsHash = storeAssets.reduce((hash, asset) => {
    hash[asset._id] = asset;
    return hash;
  }, {} as Record<string, Asset>);
  
  const tableActions = [
    <LoadingButton
      disabled={!selectedAssets.length}
      loading={isSubmitting}
      color="primary"
      variant="contained"
      onClick={() => {
        handleAssetSubmit(() => setSelectedAssets([]), selectedAssets);
      }}
    >
      {titleCase(mode)} Asset
    </LoadingButton>,
  ];


  const initialColunmnState: GridColumnsInitialState = {
    orderedFields: [],
    columnVisibilityModel: {
      _id: false,
      assetNumber: true,
      serialNumber: true,
      starfleetHours: true,
      branch: true,
      yard: true,
      status: true,
      yardPosition: true,
      category: true,
      subCategory: true,
      condition: true,
      branding: false,
      manufacturer: false,
      hrs: true,
      notes: false,
      yearOfManufacture: false,
      buildingCode: false,
      acType: false,
      heatingType: false,
      cladding: false,
      electrical: false,
      fuelType: false,
      floorStyle: false,
      sizeCode: true,
      height: false,
      structuralLimit: false,
      frame: false,
      colour: false,
      wash: true,
      partitions: true,
      complex: true,
      axleCount: false,
      windRating: false,
      roofType: false,
      inServiceDate: false,
      orientation: false,
      capitalCost: false,
      accumulatedImpairment: false,
      usefulLife: false,
      residualValue: false,
      depreciationRate: false,
      photos: false,
      version: false,
      attachments: false,
      inProduction: true,
      netBookValue: true,
    },
    dimensions: undefined,
  };




  return (
    <Dialog
      open={mode !== "hidden"}
      onClose={() => setMode("hidden")}
      maxWidth="lg"
      fullWidth
      scroll="body"
    >
      <StyledAssetsTable
        initialState={{ filter: { filterModel }, columns: initialColunmnState }}
        selectRowOnRowClick
        tableActions={tableActions}
        assets={storeAssets.filter((asset) => !currentAssets?.find((a) => a._id === asset._id))}
        tableId="add-asset-table"
        onRowClick={(params: GridRowParams<Asset>) => {
          if (enableSingleRowSelection) {
            setSelectedAssets([params.row])
          } else {
            setSelectedAssets([...selectedAssets, params.row])
          }
        }
        }
        onRowSelectionModelChange={(params) => {
          if (enableSingleRowSelection) {
            setSelectedAssets(params.length ? [assetsHash[params[params.length -1]]] : []);
          } else {
            setSelectedAssets(
              [...params].map((id) => assetsHash[id])
            );
          }
        }}
        rowSelectionModel={selectedAssets.map((asset) => asset._id)}
        disableDensitySelector
        disableExport
        persistColumnOrder={false}
        persistPinnedColumns={false}
        persistFilter={false}
        persistSort={false}
      />
    </Dialog>
  );
};

export default AddAssetModal;
