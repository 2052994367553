import React from "react";
import BillOfLadingModal from "./BillOfLadingModal";

const Modals: React.FC = () => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    (<>
      <BillOfLadingModal />
     </>)
  );
};
export default Modals;
