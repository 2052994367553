import React, { ReactNode, Suspense } from "react";
import { Box, CircularProgress } from "@mui/material";
import { Outlet } from "react-router";

interface Props {
  children?: ReactNode
}

const OptisignsScreen: React.FC<Props> = ({ children }) => {
  return (
    <Suspense
      fallback={
        <Box
          sx={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={48} />
        </Box>
      }
    >
      <Outlet />
    </Suspense>
  );
};

export default OptisignsScreen;
