import { Typography, Grid, Card, Box, CircularProgress } from "@mui/material";
import ImageCarousel from "components/image/ImageCarousel";
import { FC, ReactNode } from "react";
import { IChecklistItem } from "store/models/Inspection";
import { Photo } from "store/models/Photo";
import { fCurrency } from "utils/formatNumber";
import { dateFromMMDDYYYY, toDDMMMYYYY } from "utils/util";
import CheckListTasks from "./modal/CheckListTasks";

interface ListItemRowProps {
  label: string;
  value?: ReactNode;
}
const ListItemRow: FC<ListItemRowProps> = ({ label, value }) => {
  return (
    <Grid container alignItems="center" mb={1.5} minHeight="40px">
      <Grid item xs={4} sx={{ color: "text.secondary" }}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid
        item
        xs={8}
        sx={{ textAlign: typeof value === "string" ? "right" : "" }}
      >
        {value}
      </Grid>
    </Grid>
  );
};

interface Props {
  checkListItem: IChecklistItem;
  images: Photo[];
  isFetchingImages: boolean;
  handleImageUpload: (form: FormData) => Promise<any>;
  deleteImage: (photo: Photo) => void;
}

const CheckListItemView: FC<Props> = ({
  checkListItem,
  images,
  isFetchingImages,
  handleImageUpload,
  deleteImage,
}) => {
  return (
    <>
      {isFetchingImages ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <ImageCarousel
          handleImageUpload={handleImageUpload}
          allowMultiple
          deleteImage={deleteImage}
          images={images}
          permission={false}
          boxSx={{
            width: "100%",
          }}
        />
      )}
      <Card sx={{ p: 3 }}>
        <Grid container direction="row" alignItems="center" spacing={1}>
          <ListItemRow label="Title" value={checkListItem.title} />

          <ListItemRow label="Description" />
          <Typography sx={{ mt: -1, mb: 2 }}>
            {checkListItem.description}
          </Typography>

          <ListItemRow label="Priority" value={checkListItem.priority} />

          <ListItemRow label="Tasks" />
          <CheckListTasks
            tasks={checkListItem.tasks}
            disabled
            addTask={() => {}}
            completeTask={() => {}}
            deleteTask={() => {}}
          />
          <ListItemRow
            label="Item Quantity"
            value={checkListItem.quantity}
          />
          <ListItemRow
            label="Due Date"
            value={toDDMMMYYYY(dateFromMMDDYYYY(checkListItem.dueDate))}
          />

          <ListItemRow
            label="Category"
            value={checkListItem.workOrderCategory}
          />
          <ListItemRow
            label="Est. Hours"
            value={checkListItem.estimatedHours}
          />

          <ListItemRow
            label="Est. Material Cost"
            value={`${fCurrency(checkListItem.estimatedMaterials.amount)} ${
              checkListItem.estimatedMaterials.currency
            }`}
          />
        </Grid>
      </Card>
    </>
  );
};

export default CheckListItemView;
