import { getQueryStringFromObject } from "store/api/util";
import { Client } from "store/models/Client";
import { starfleetApi } from "store/services/api";
import { getResponseBody } from "store/services/utils";

export const clientApi = starfleetApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getClients: builder.query<Client[], void>({
      query: (query) => `client`,
      transformResponse: getResponseBody,
      providesTags: [{ type: "Clients", id: `LIST` }],
    }),
    getClientById: builder.query<Client, string>({
      query: (id) =>
        `client/single?${getQueryStringFromObject({ clientId: id })}`,
      transformResponse: getResponseBody,
      providesTags: (result, err, id) => [{ type: "Clients", id }],
    }),
    updateClient: builder.mutation<Client, Partial<Client>>({
      query: (body) => ({
        url: `client`,
        method: "PUT",
        body,
      }),
      transformResponse: getResponseBody,
      invalidatesTags: (result, error, body) => [
        { type: "Clients", id: body._id },
        { type: "Clients", id: `LIST` },
      ],
      extraOptions: { maxRetries: 0 },
    }),
    createClient: builder.mutation<Client, Partial<Client>>({
      query: (body) => ({
        url: `client`,
        method: "POST",
        body,
      }),
      transformResponse: getResponseBody,
      invalidatesTags: [{ type: "Clients", id: `LIST` }],
      extraOptions: { maxRetries: 0 },
    }),
    mergeClients: builder.mutation<
      Client,
      { mergeToClientId: string; clientIdToRemove: string }
    >({
      query: (body) => ({
        url: `client/merge`,
        method: "POST",
        body,
      }),
      transformResponse: getResponseBody,
      invalidatesTags: (
        result,
        error,
        { mergeToClientId, clientIdToRemove }
      ) => [
        { type: "Clients", id: mergeToClientId },
        { type: "Clients", id: clientIdToRemove },
        { type: "Clients", id: `LIST` },
        { type: "ClientContact", id: "QUERY" },
        { type: "ClientContracts", id: "LIST" },
        { type: "ClientContracts", id: mergeToClientId },
        "Contracts",
        'ClientContact'
      ],
      extraOptions: { maxRetries: 0 },
    }),
  }),
});

export const {
  useGetClientsQuery,
  useGetClientByIdQuery,
  useCreateClientMutation,
  useUpdateClientMutation,
  useMergeClientsMutation
} = clientApi;
