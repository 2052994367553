import numeral from 'numeral';
import { ICurrency } from 'types/Currency';

// ----------------------------------------------------------------------

// 0 formats to $ 0 and 0.004... formats to $ 0.00
export function fCurrency(number: string | number | undefined) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPotentiallyUndefinedCurrency(value: ICurrency | undefined) {
  if (!value) return ""
  return `${fCurrency(value.amount || "")} ${value.currency}`
}


// numbers format to $ {x.toFixed(2)}
export function fCurrencyFloat(number: string | number) {
  return numeral(number).format('$0,0.00');
}

export function removeCentsFromCurrency(value: number) {
  return fCurrency(value).split(".")[0];
}

export function fPercent(number: number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number: string | number) {
  return numeral(number).format();
}
