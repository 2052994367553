import React, { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import {
  setCurrentContractId,
  selectCurrentContractId,
} from "store/slices/contractSlice";
import { AssetDeliverable } from "store/models/AssetDeliverable";
import AssetMovements from "./AssetMovements";
import {
  setCurrentAssetId,
} from "store/slices/assetSlice";
import { dateFromMMDDYYYY, isMMDDYYYY, titleCase, toDDMMMYYYY, toMMDDYYYY } from "utils/util";
import { isValid } from "date-fns";
import usePermissions, {
  ActionType,
  FeatureType,
} from "hooks/usePermissions";
import {
  Box,
  CardHeader,
  Typography,
  Card,
  CardContent,
  Button,
  Chip,
  IconButton,
  Tooltip,
} from "@mui/material";
import MaintenanceIcon from '@mui/icons-material/Handyman';
import DateSelector from "components/DateSelector";
import { setBladeMode } from "store/slices/systemSlice";
import { AddAssetModalMode } from "components/modals/AddAssetModal";
import { useAppDispatch } from 'store/store';
import { fCurrency } from "utils/formatNumber";
import Dot from "components/Dot";
import Movement from "store/models/Movement";
import Edit from "@mui/icons-material/Edit";
import CurrencyTextField from "components/CurrencyTextField";
import { Currency } from "dinero.js";
import CopyClipboard from "components/CopyClipboard";
import { GridFilterModel } from "@mui/x-data-grid-pro";
import { useGetContractByIdQuery, useUpdateAssetDeliverablesMutation } from "store/services/contract";
import { Asset } from "store/models/Asset";
import { PickerChangeHandlerContext, DateValidationError } from "@mui/x-date-pickers-pro";

interface Props {
  assetDeliverable: AssetDeliverable;
  sameBillingStart: boolean;
  sameBillingEnd: boolean;
  index: number;
  setAddAssetMode: (mode: AddAssetModalMode, filters?: GridFilterModel, assetDeliverable?: AssetDeliverable) => void;
  setNewMovements: Dispatch<SetStateAction<Movement[]>>;
  changeIsSubmitting: Dispatch<SetStateAction<boolean>>;
  asset?: Asset
}

const ContractAsset: React.FC<Props> = ({
  assetDeliverable,
  sameBillingStart,
  sameBillingEnd,
  index,
  setAddAssetMode,
  setNewMovements,
  changeIsSubmitting,
  asset
}) => {

  const currentContractId = useSelector(selectCurrentContractId);
  const {data: currentContract} = useGetContractByIdQuery(currentContractId || "", {skip: !currentContractId});
  const [unsavedAssetDeliverable, changeUnsavedAssetDeliverable] = useState<AssetDeliverable>(assetDeliverable);

  const dispatch = useAppDispatch();
  const [isEditing, setEditing] = useState(false);
  const checkContractPermissions = usePermissions(FeatureType.CONTRACT);
  const userCanEditContracts = checkContractPermissions(ActionType.UPDATE);
  const [updateAssetDeliverables] = useUpdateAssetDeliverablesMutation();

  const checkAssetPermissions = usePermissions(FeatureType.ASSET);
  const userCanViewAssets = checkAssetPermissions(ActionType.READ);

  const assetMovements = currentContract?.movements.filter((move) => move.asset === asset?._id) || [];
  const latestAssetMovement: Movement | undefined = assetMovements[assetMovements.length - 1];

  const requestedDeliveryDate = () => (
    <DateSelector
      data-testid="dateOfMove"
      onChange={onDateOfMoveChange}
      onAccept={handleDateOfMoveAccept}
      value={dateFromMMDDYYYY(unsavedAssetDeliverable.deliveryDate)}
      label="Delivery Date"
      textFieldProps={{
        size: "small",
        fullWidth: true,
      }}
    />
  );

  const nextMovement = () => {
    return (
      <Box sx={{whiteSpace: "nowrap", width: "100%", ml: 0.2}}>
        {latestAssetMovement?.dateOfMove
          ? `${latestAssetMovement?.type}: ${toDDMMMYYYY(dateFromMMDDYYYY(latestAssetMovement?.dateOfMove))}` : "???"}
      </Box>
    );
  };

  function onDateOfMoveChange(value: Date | null, context: PickerChangeHandlerContext<DateValidationError>) {
    if (!currentContract || !value) return; 

    if (isValid(value) && userCanEditContracts) {
      changeUnsavedAssetDeliverable((previousValues) => ({
        ...previousValues,
        deliveryDate: toMMDDYYYY(value),
      }));

      changeIsSubmitting(true)
      
      updateAssetDeliverables({contractId: currentContract._id, assetDeliverables: [{...unsavedAssetDeliverable, deliveryDate: toMMDDYYYY(value) }]}).unwrap()
      .catch(() => changeIsSubmitting(false))
      .finally(() => changeIsSubmitting(false))
  }
}

  const handleDateOfMoveAccept = (date: unknown) => {
    const newDate = date as Date;
    changeUnsavedAssetDeliverable((previousValues) => ({
      ...previousValues,
      deliveryDate: toMMDDYYYY(newDate),
    }));
  };

  const handleBillingDateAccept =
    (billingDateKey: "customBillingStart" | "customBillingEnd") =>
    (date: unknown) => {
      const newDate = date as Date;
      changeUnsavedAssetDeliverable((previousValues) => ({
        ...previousValues,
        [billingDateKey]: toMMDDYYYY(newDate),
      }));
    };

  function onBillingDateChange(
    billingDateKey: "customBillingStart" | "customBillingEnd",
    index: number
  ) {
    return function (value: Date | null, context: PickerChangeHandlerContext<DateValidationError>) {
      if (!currentContract || !value) return;
      if (value === null || (isValid(value) && userCanEditContracts)) {
        const updatedDeliverables = shouldChangeAllDeliverables()
          ? currentContract.assetDeliverables.map<AssetDeliverable>((deliverable) => {
              return {
                ...deliverable,
                [billingDateKey]: value ? toMMDDYYYY(value) : value,
              };
            })
          : [{...unsavedAssetDeliverable, [billingDateKey]: value ? toMMDDYYYY(value) : value}]; 


        changeUnsavedAssetDeliverable((previousValues) => ({
          ...previousValues,
          [billingDateKey]: value ? toMMDDYYYY(value) : value,
        }));

        changeIsSubmitting(true);

        updateAssetDeliverables({contractId: currentContract._id, assetDeliverables: updatedDeliverables}).unwrap()
        .catch(() => changeIsSubmitting(false))
        .finally(() => changeIsSubmitting(false))


        function shouldChangeAllDeliverables() {
          return (
            index === 0 &&
            ((billingDateKey === "customBillingEnd" && sameBillingEnd) ||
              (billingDateKey === "customBillingStart" && sameBillingStart))
          );
        }
      }
    };
  }

  function invalidAmount(rate: number){
    return !rate || rate <= 0
  }


  function onAmountOrCurrencyChange({type, field, value}: {type: "amount" | "currency", field: "rentalRate" | "saleAmount", value: string | number}) {
    if (!userCanEditContracts || !currentContract) return;
    changeUnsavedAssetDeliverable((previousValues) => ({
      ...previousValues,
      [field]: {
        ...previousValues[field],
        [type]: value,
      },
    }));
  }

  function onRentalOrSaleBlur(field: "rentalRate" | "saleAmount") {
    if (invalidAmount(unsavedAssetDeliverable[field].amount || 0) || !currentContract) return;
    changeIsSubmitting(true)

    updateAssetDeliverables({contractId: currentContract._id, assetDeliverables: [unsavedAssetDeliverable]}).unwrap()
    .catch(() => changeIsSubmitting(false))
    .finally(() => changeIsSubmitting(false))
  }

  function handleCreateWorkOrder() {
    if (!currentContract || !asset) return;
    dispatch(setCurrentAssetId(asset._id));
    dispatch(setCurrentContractId(currentContract._id));
    dispatch(setBladeMode("workOrderCreate"));
  }
  if (!asset) {
    return null;
  }
  if (!asset) {
    return null;
  }

  return (
    <Card>
      <CardHeader
        disableTypography
        title={
          <>
            <Button
              onClick={() => {
                dispatch(setCurrentAssetId(asset._id));
                dispatch(setBladeMode("assetViewEdit"));
              }}
              disabled={!userCanViewAssets}
              color="inherit"
              sx={{ ml: -1 }}
            >
              <Typography variant="h5">
                {asset.serialNumber} - {asset.sizeCode}
              </Typography>
            </Button>
            <CopyClipboard
              value={asset.serialNumber}
              tooltipText="Copy Serial #"
              successText={`Copied asset Serial #: ${asset.serialNumber}`}
            />
            <Tooltip title="Create Work Order" placement="top">
              <IconButton onClick={handleCreateWorkOrder} key="newWorkOrder">
                <MaintenanceIcon />
              </IconButton>
            </Tooltip>
          </>
        }
        action={
          !assetDeliverable.isActiveOnContract ? (
            <Chip label={<Typography>Not on Contract</Typography>} />
          ) : (
            <IconButton
              onClick={() => setEditing((isEditing) => !isEditing)}
              sx={{ ml: 1 }}
              disabled={
                !Boolean(asset.status !== "SOLD" && userCanEditContracts)
              }
            >
              <Edit />
            </IconButton>
          )
        }
      />
      <CardContent sx={{ pt: 0 }}>
        {isEditing ? (
          <>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
              <Dot
                color={asset.status === "AVAILABLE" ? "success" : "error"}
                sx={{ mb: 1 }}
              />
              {titleCase(asset.status)}:{" "}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
                py: 1,
                mb: 1,
              }}
            >
              {currentContract?.contractType !== "Sale" && (
                <>
                  <DateSelector
                    data-testid="customBillingStart"
                    onChange={onBillingDateChange("customBillingStart", index)}
                    onAccept={handleBillingDateAccept("customBillingStart")}
                    disabled={sameBillingStart && index > 0}
                    value={
                      unsavedAssetDeliverable.customBillingStart
                        ?
                            dateFromMMDDYYYY(
                              unsavedAssetDeliverable.customBillingStart
                            )
                          
                        : null
                    }
                    label="Custom Billing Start"
                    textFieldProps={{
                      size: "small",
                      fullWidth: true,
                    }}
                  />
                  <DateSelector
                    data-testid="customBillingEnd"
                    onChange={onBillingDateChange("customBillingEnd", index)}
                    onAccept={handleBillingDateAccept("customBillingEnd")}
                    disabled={sameBillingEnd && index > 0}
                    value={
                      unsavedAssetDeliverable.customBillingEnd
                        ? 
                            dateFromMMDDYYYY(
                              unsavedAssetDeliverable.customBillingEnd
                            )
                          
                        : null
                    }
                    label="Custom Billing End"
                    textFieldProps={{
                      size: "small",
                      fullWidth: true,
                    }}
                  />
                </>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              {currentContract?.movements.filter(
                (move) => move.asset === asset._id
              ).length
                ? nextMovement()
                : requestedDeliveryDate()}
              {currentContract?.contractType === "Rental" ? (
                <CurrencyTextField
                  label="Rental Rate"
                  size="small"
                  fullWidth
                  value={unsavedAssetDeliverable.rentalRate}
                  error={
                    !unsavedAssetDeliverable.rentalRate.amount ||
                    unsavedAssetDeliverable.rentalRate.amount <= 0
                  }
                  disabled={
                    !userCanEditContracts ||
                    currentContract.status === "CONCLUDED" ||
                    !assetDeliverable.isActiveOnContract
                  }
                  onChange={(e) =>
                    onAmountOrCurrencyChange({
                      type: "amount",
                      field: "rentalRate",
                      value: e.target.value,
                    })
                  }
                  onCurrencyChange={(e) =>
                    onAmountOrCurrencyChange({
                      type: "currency",
                      field: "rentalRate",
                      value: e.target.value as Currency,
                    })
                  }
                  onBlur={() => onRentalOrSaleBlur("rentalRate")}
                  variant="outlined"
                  inputProps={{
                    "data-testid": "dateOfMove",
                  }}
                />
              ) : (
                <CurrencyTextField
                  size="small"
                  label="Sale Amount"
                  value={unsavedAssetDeliverable.saleAmount}
                  error={
                    !unsavedAssetDeliverable.saleAmount.amount ||
                    unsavedAssetDeliverable.saleAmount.amount <= 0
                  }
                  onChange={(e) =>
                    onAmountOrCurrencyChange({
                      type: "amount",
                      field: "saleAmount",
                      value: e.target.value,
                    })
                  }
                  fullWidth
                  onCurrencyChange={(e) =>
                    onAmountOrCurrencyChange({
                      type: "currency",
                      field: "saleAmount",
                      value: e.target.value as Currency,
                    })
                  }
                  disabled={
                    !userCanEditContracts ||
                    currentContract?.status === "CONCLUDED" ||
                    !assetDeliverable.isActiveOnContract
                  }
                  onBlur={() => onRentalOrSaleBlur("saleAmount")}
                />
              )}
            </Box>
          </>
        ) : (
          <Box
            sx={{ display: "flex", flexDirection: "column", rowGap: 1, mt: 1 }}
          >
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
              <Dot color={asset.status === "AVAILABLE" ? "success" : "error"} />
              {titleCase(asset.status)}
            </Box>
            {Boolean(
              assetDeliverable.customBillingStart ||
                assetDeliverable.customBillingEnd
            ) && (
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "100%" }}>
                  <Typography
                    component="span"
                    sx={(theme) => ({ color: theme.palette.text.secondary })}
                  >
                    Start:{" "}
                  </Typography>
                  {assetDeliverable.customBillingStart ||
                    currentContract?.startDate}
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography
                    component="span"
                    sx={(theme) => ({ color: theme.palette.text.secondary })}
                  >
                    End:{" "}
                  </Typography>
                  {assetDeliverable.customBillingEnd ||
                    currentContract?.endDate ||
                    (currentContract?.evergreen && "Evergreen")}
                </Box>
              </Box>
            )}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ width: "100%" }}>
                <Typography sx={{ width: "100%" }}>
                  <Typography
                    component="span"
                    sx={(theme) => ({ color: theme.palette.text.secondary })}
                  >
                    {latestAssetMovement?.type || "No Movements"}:{" "}
                  </Typography>
                  {latestAssetMovement &&
                    toDDMMMYYYY(
                      dateFromMMDDYYYY(latestAssetMovement?.dateOfMove)
                    )}
                </Typography>
              </Box>

              <Box sx={{ width: "100%" }}>
                {currentContract?.contractType === "Rental" ? (
                  <Box sx={{ width: "100%" }}>
                    <Typography
                      component="span"
                      sx={(theme) => ({
                        color: assetDeliverable.rentalRate.amount
                          ? theme.palette.text.secondary
                          : theme.palette.error.main,
                      })}
                    >
                      Rental Rate:{" "}
                    </Typography>
                    <Typography
                      component="span"
                      sx={(theme) => ({
                        color: assetDeliverable.rentalRate.amount
                          ? theme.palette.text.secondary
                          : theme.palette.error.main,
                      })}
                    >
                      {fCurrency(assetDeliverable.rentalRate?.amount)}
                    </Typography>
                  </Box>
                ) : (
                  <Box sx={{ width: "100%" }}>
                    <Typography
                      component="span"
                      sx={(theme) => ({ color: theme.palette.text.secondary })}
                    >
                      Sale Amount:{" "}
                    </Typography>
                    {fCurrency(assetDeliverable.saleAmount?.amount)}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        )}
        <AssetMovements
          assetDeliverable={assetDeliverable}
          asset={asset}
          setAddAssetMode={setAddAssetMode}
          setNewMovements={setNewMovements}
          changeIsSubmitting={changeIsSubmitting}
          disabled={["CONCLUDED", "CANCELLED", "DENIED"].includes(
            currentContract?.status || ""
          )}
        />
      </CardContent>
    </Card>
  );
};
export default ContractAsset;
