import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import ConfirmationModal from "components/ConfirmationModal";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import ContractAccessoryForm from "./ContractAccessoryForm";
import {
  selectCurrentContractId,
} from "store/slices/contractSlice";
import { selectLoggedInUser, simpleGlobalMessage } from "store/slices/systemSlice";
import usePermissions, {
  ActionType,
  FeatureType,
} from "hooks/usePermissions";
import { useAppDispatch } from 'store/store';
import { dateFromMMDDYYYY, toDDMMMYYYY } from "utils/util";
import MovementAccordion from "./AccessoryMovementAccordion";
import IAccessory from "store/models/Accessory";
import { selectBranches } from "store/slices/optionSlice";
import { useGetContractByIdQuery, useRemoveAccessoryMutation } from "store/services/contract";
import { useGetAssetsByContractIdQuery } from "store/services/asset";
import { byIds } from "store/sliceUtils";

interface Props {
  accessory: IAccessory;
  changeIsSubmitting: Dispatch<SetStateAction<boolean>>
}

const ContractAccessory: FC<Props> = ({ accessory, changeIsSubmitting }) => {
  const currentContractId = useSelector(selectCurrentContractId);
  const {data: assets = []} = useGetAssetsByContractIdQuery(currentContractId || "", {skip: !currentContractId});
  const assetsHash = byIds(assets);

  const {data: currentContract} = useGetContractByIdQuery(currentContractId || "", {skip: !currentContractId});
  const [removeAccessory] = useRemoveAccessoryMutation();
  const dispatch = useAppDispatch();
  const branches = useSelector(selectBranches)
  const loggedInUser = useSelector(selectLoggedInUser);
  const checkContractPermissions = usePermissions(FeatureType.CONTRACT);
  const userTryingToEditOwnContract =
    currentContract?.createdBy === loggedInUser?._id;
  const userCanEditOwnContracts =
    checkContractPermissions(ActionType.LIMITED_UPDATE) &&
    userTryingToEditOwnContract;
  const userCanEditAllContracts = checkContractPermissions(ActionType.UPDATE);
  const [mode, changeMode] = useState<"view" | "edit">("view");

  function editMode() {
    if (allowedToEdit()) {
      changeMode(mode === "view" ? "edit" : "view");
    } else {
      dispatch(
        simpleGlobalMessage("You do not have permission to edit this accessory")
      );
    }
  }

  function allowedToEdit() {
    if (!currentContract) return;
    const userCanEditAccessories =
      userCanEditAllContracts || userCanEditOwnContracts;
    return (
      !["CANCELLED", "CONCLUDED", "DENIED"].includes(currentContract?.status) &&
      userCanEditAccessories
    );
  }

  function handleRemoveAccessory() {
    if (!currentContract) return;
    changeIsSubmitting(true)
    removeAccessory({contractId: currentContract._id, accessoryToRemoveId: accessory._id}).unwrap().then(({ contract }) => {
      ;
    }).finally(() => {
      changeIsSubmitting(false)
    })
  }

  return (
    <Card>
      {mode === "view" ? (
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 1,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between"}}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: 1, flex: 1 }}>
              <Typography noWrap variant="h6">
                {accessory.name}
              </Typography>
              x{accessory.quantity} {accessory.unitOfMeasure}
            </Box>
            <Box sx={{ justifySelf: "flex-end" }}>
              <IconButton size="small" onClick={editMode}>
                <Edit />
              </IconButton>
              <ConfirmationModal
                handleConfirmation={handleRemoveAccessory}
                message={`${accessory.name} x${accessory.quantity} ${accessory.unitOfMeasure} will be removed from this contract`}
                sx={{ display: "inline" }}
                disabled={!allowedToEdit()}
              >
                <Tooltip
                  title={
                    !allowedToEdit()
                      ? `You do not have permission to remove this accessory`
                      : "Remove"
                  }
                >
                  <IconButton>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </ConfirmationModal>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box>
              {accessory.asset
                ? `${assetsHash[accessory.asset]?.serialNumber} - ${
                    assetsHash[accessory.asset]?.sizeCode
                  }`
                : "No Asset Assigned"}
            </Box>
            <Box>
              {accessory.branch
                ? `Branch: ${branches[accessory.branch].name} `
  
                : "No Branch Assigned"}
            </Box>
            <Box
              sx={{
                justifySelf: "flex-end",
              }}
            >
              ${accessory.rate.amount} {accessory.rate.currency}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box>
              Start Date:{" "}
              {accessory.customBillingStart ||
                toDDMMMYYYY(dateFromMMDDYYYY(currentContract?.startDate || "0-0-0"))}
            </Box>
            <Box>
              End Date:{" "}
              {accessory.customBillingEnd ||
                (currentContract?.endDate &&
                  toDDMMMYYYY(dateFromMMDDYYYY(currentContract?.endDate))) ||
                "Evergreen"}
            </Box>
          </Box>
        </CardContent>
      ) : (
        <ContractAccessoryForm accessory={accessory} changeMode={changeMode} />
      )}
      <MovementAccordion accessory={accessory} changeIsSubmitting={changeIsSubmitting} />
    </Card>
  );
};

export default ContractAccessory;
