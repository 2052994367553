import { ICurrency } from 'types/Currency';
import { MMDDYYYY } from 'utils/util';
import IAccessory from './Accessory';
import Address from './Address';
import { Asset } from './Asset';
import { AssetDeliverable } from './AssetDeliverable';
import Movement from './Movement';

export interface DeliveryAddress {
  dateOfMove: MMDDYYYY;
  address: string;
}


export type ContractStatus = 
  | "AWAITING APPROVAL"
  | "AWAITING CONFIRMATION"
  | "DENIED"
  | "CANCELLED"
  | "PENDING DELIVERY"
  | "ACTIVE"
  | "CONCLUDED"

export interface Contract<assetType = string> {
  _id: string;
  branches: Asset["branch"][]
  assetDeliverables: AssetDeliverable<assetType>[];
  contractSigned: boolean;
  status: ContractStatus;
  contractType: "Sale" | "Rental";
  customerName: string;
  endDate: MMDDYYYY | null;
  outDate: MMDDYYYY;
  reservationExpiry: MMDDYYYY;
  returnDate?: MMDDYYYY;
  startDate: MMDDYYYY;
  additionalComments: string;
  deliveryInstructions: string;
  projectNumber: string;
  actionRequired?: {
    actionName: string;
    urgency: "none" | "yellow" | "red";
  };
  createdBy: string;
  attachments?: string[];
  /**
   * evergreen is only used when sending a contract to the backend to communicate to the backend that a null endDate is on purpose.
   * it is a fancy accounting term for a contract with no set endDate
   */
  evergreen: boolean;
  client: string;
  movements: Movement[];
  billTo: Address;
  shipTo: Address;
  accessories: IAccessory[];
  securityDeposit: {
    received: boolean, 
    amount: ICurrency,
  };
  clientPoNumber: string;
  quoteNumber: string;
  ppsaRegistration: string
  clientContact: string | null;
  siteContact: string | null
}

export const activeContractStatuses: ContractStatus[] = ["ACTIVE", "PENDING DELIVERY"]
export const inactiveContractStatuses: ContractStatus[] = ["CANCELLED", "CONCLUDED", "DENIED"];
export const canceleedContractStatuses: ContractStatus[] = ["CANCELLED", "DENIED"];
export const billableContractStatuses: ContractStatus[] = ["PENDING DELIVERY", "ACTIVE", "CONCLUDED"];
export const awaitingContractStatuses: ContractStatus[] = ["AWAITING CONFIRMATION", "AWAITING APPROVAL"]
export const allContractStatuses: ContractStatus[] = ["AWAITING APPROVAL", "AWAITING CONFIRMATION", "PENDING DELIVERY", "ACTIVE", "CONCLUDED", "DENIED", "CANCELLED"]
