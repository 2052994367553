import React, { FC, ReactNode } from "react";
import { Box } from "@mui/material";

const NoWrap: FC<{children?: ReactNode}> = ({ children }) => {
  return (
    <Box sx={{ whiteSpace: "nowrap"}}>
      {children}
    </Box>
  )
}

export default NoWrap;