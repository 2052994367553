import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { STARFLEET_STORE } from "store/store";
import { Asset } from "store/models/Asset";
import { AssetStatus } from "store/models/AssetStatus";
import { MMDDYYYY } from "utils/util";
import { ICurrency } from "types/Currency";
import _ from "lodash";


export type AssetQueryArgs = {
  statuses: AssetStatus[];
};

export const defaultAssetFiltersStorageIdentifier = 'defaultAssetFilters';

export const initialAssetStatuses: AssetStatus[] = ["AVAILABLE", "RESERVED", "SOLD PENDING DELIVERY"];

const defaultAssetFilters = localStorage.getItem(defaultAssetFiltersStorageIdentifier);


export type AssetWithFinancialData = Asset & {currentRentalRate?: ICurrency, payBack?: number, starfleetHours?: number};

export type ASSETS_STORE = {
  assetFilters: AssetQueryArgs;
  currentAssetId?: Asset["_id"];
  selectedAssetIds: Asset["_id"][];
  checkedAssetIds: Asset["_id"][];
  pinnedAssetIds: Asset["_id"][];
};

const initialState: ASSETS_STORE = {
  assetFilters: {
    statuses: defaultAssetFilters ? JSON.parse(defaultAssetFilters).statuses : initialAssetStatuses,
  },
  selectedAssetIds: [],
  checkedAssetIds: [],
  pinnedAssetIds: [],
  currentAssetId: undefined,
};

const assetSlice = createSlice({
  name: "asset",
  initialState,
  reducers: {
    setCurrentAssetId(state, action: PayloadAction<Asset["_id"]>) {
      state.currentAssetId = action.payload;
    },
    clearCurrentAssetId(state) {
      state.currentAssetId = undefined;
    },
    addSelectedAssetId(state, action: PayloadAction<Asset["_id"]>) {
      state.selectedAssetIds.push(action.payload);
    },
    removeSelectedAssetId(state, action: PayloadAction<Asset["_id"]>) {
      state.selectedAssetIds = state.selectedAssetIds.filter(
        (_id) => _id !== action.payload
      );
    },
    clearAllSelectedAssets(state) {
      state.selectedAssetIds = [];
    },
    clearAllPinnedAssets(state) {
      state.pinnedAssetIds = [];
    },
    addPinnedAssetId(state, action: PayloadAction<Asset["_id"]>) {
      state.pinnedAssetIds = [...state.pinnedAssetIds, action.payload];
    },
    removePinnedAssetId(state, action: PayloadAction<Asset["_id"]>) {
      state.pinnedAssetIds = [
        ...state.pinnedAssetIds.filter((_id) => _id !== action.payload),
      ];
    },
    setSelectedAssetIds(state, action: PayloadAction<Asset["_id"][]>) {
      state.selectedAssetIds = [...action.payload];
    },
    clearAllSelectedAssetIds(state) {
      state.selectedAssetIds = [];
    },
    clearAllPinnedAssetIds(state) {
      state.pinnedAssetIds = [];
    },
    setAssetQueryArgs(state, action: PayloadAction<AssetQueryArgs>) {
      state.assetFilters = action.payload;
    },
  },
});

export const selectAssetQueryArgs = (state: STARFLEET_STORE) => state.assets.assetFilters;

export const selectSelectedAssetIds = (state: STARFLEET_STORE) => state.assets.selectedAssetIds;

export const selectCurrentAssetId = (state: STARFLEET_STORE) => state.assets.currentAssetId;


export interface MasterUnitRecord {
  assetId: string;
  asset: string;
  branch: string;
  status: AssetStatus;
  client: string | null;
  projectNumber: string | null;
  startDate: MMDDYYYY | null;
  endDate: MMDDYYYY | null;
  billTo: string | null;
  shipTo: string | null;
  rentalRate: ICurrency | null;
  saleAmount: ICurrency | null,
  monthlyDepreciation: string | null,
  payback: string | null,
}

export const selectAssetsByStatus = (assets: Asset[]) => {
  return assets.reduce((assetsByStatus: Record<AssetStatus, Asset[]>, asset) => {
   assetsByStatus[asset.status] ? assetsByStatus[asset.status].push(asset) : assetsByStatus[asset.status] = [asset]
    return assetsByStatus
  }, {
      "AVAILABLE": [],
      "RESERVED": [],
      "ON LEASE": [],
      "SOLD": [],
      "SOLD PENDING DELIVERY": [],
      "MAINTENANCE NEEDED": [],
      "NOT RENTABLE": [],
      "IN MAINTENANCE": [],
      "INSPECT": [],
    })
};

export const activeStatuses = ["SOLD PENDING DELIVERY", "ON LEASE"];
export const statusesToExcludeFromDashboard = ["SOLD"]

export function assetIsInSelectedBranches(asset: Asset, selectedBranchIds: string[]) {
  return selectedBranchIds.length
    ? selectedBranchIds.includes(asset.branch)
    : true;
}

export function getBranchAssetsNotInProduction(assets: Asset[], selectedBranchIds: string[]) {
  return assets.filter(asset => selectedBranchIds.length ? (selectedBranchIds.includes(asset.branch) && !asset.inProduction) : true)
}

export const assetFilterValuesAreDefault = (state: STARFLEET_STORE) => {
  const {statuses } = state.assets.assetFilters
  const noStatusChanges = _.isEqual(initialAssetStatuses, statuses);
  return noStatusChanges
}



export const {
    setCurrentAssetId,
    clearCurrentAssetId,
    addSelectedAssetId,
    removeSelectedAssetId,
    clearAllSelectedAssets,
    clearAllPinnedAssets,
    addPinnedAssetId,
    removePinnedAssetId,
    setSelectedAssetIds,
    clearAllSelectedAssetIds,
    clearAllPinnedAssetIds,
    setAssetQueryArgs
} = assetSlice.actions;

export default assetSlice.reducer;
