import React from "react";
import { Field, FastFieldProps } from "formik";
import { PickerChangeHandlerContext, renderTimeViewClock, TimePicker, TimeValidationError } from "@mui/x-date-pickers-pro";

const FastTimeInput: React.FC<{
  label: string;
  name: string;
  onChange?: (
    value: any,
    contex?: PickerChangeHandlerContext<TimeValidationError>
  ) => void;
}> = ({ label, name, onChange, ...props }) => {
  return (
    <Field {...props} name={name}>
      {({ field, form, meta }: FastFieldProps) => {
        return (
          <TimePicker
            {...field}
            {...props}
            format="hh:mm a"
            label={label}
            slotProps={{
              textField: {
                variant: "outlined",
                size: "small",
                fullWidth: true,
                error: Boolean(meta.touched && meta.error),
                name: name,
              },
            }}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
            value={field.value ? new Date(field.value) : '' as any}
            onChange={(newTime, ...props) => {
              if (onChange) {
                onChange(newTime, ...props);
              } else {
                form.setFieldValue(name, newTime);
              }
            }}
          />
        );
      }}
    </Field>
  );
};

export default FastTimeInput;
