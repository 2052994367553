import React from "react";
import { Field, FastFieldProps } from "formik";
import { isValid } from "date-fns/fp";
import { dateFromMMDDYYYY, isMMDDYYYY, toMMDDYYYY } from "utils/util";
import { DatePicker } from "@mui/x-date-pickers-pro";

const FastDateInput: React.FC<{ label: string; name: string }> = ({
  label,
  name,
  ...props
}) => {
  return (
    <Field {...props} name={name}>
      {({ field, form, meta }: FastFieldProps) => {

        return (
          <DatePicker
            {...field}
            value={
              typeof field.value === "string" && isMMDDYYYY(field.value)
                ? dateFromMMDDYYYY(field.value)
                : typeof field.value === "string" &&
                  isValid(new Date(field.value))
                ? new Date(field.value)
                : field.value
            }
            label={label}
            openTo="year"
            format="dd-MMM-yyyy"
            localeText={{
              fieldMonthPlaceholder: () => 'MMM',
            }}
            slotProps={{
              textField: {
                variant: "outlined",
                size: "small",
                fullWidth: true,
                error: Boolean(meta.touched && meta.error),
                name: name,
              },
            }}
            onChange={(newDate) => {
              if (isValid(newDate)) {
                form.setFieldValue(name, toMMDDYYYY(newDate as Date));
              } else {
                form.setFieldValue(name, newDate);
              }
            }}
          />
        );
      }}
    </Field>
  );
};

export default FastDateInput;
