import React, { FC, ReactNode, useState } from "react";
import { useSelector } from "react-redux";
import { selectUsers } from "store/slices/userSlice";
import { Priority, WorkOrderStatus } from "store/models/WorkOrder";
import {
  dateFromMMDDYYYY,
  getFullName,
  isOfDocumentType,
  IsTypeOfContract,
  toDDMMMYYYY,
  toMMDDYYYY,
} from "utils/util";
import {
  FastField,
  FastFieldProps,
  Field,
  useFormikContext,
  getIn,
} from "formik";
import {
  Card,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { PrimitiveSelect } from "components/select";
import { AssetAutoComplete, UserAutoComplete } from "components/autocomplete";
import DateSelector from "components/DateSelector";
import { Asset } from "store/models/Asset";
import UserData from "store/models/UserData";
import Tasks from "features/maintenance/Tasks";
import NumberTextField from "components/NumberTextField";
import CurrencyTextField from "components/CurrencyTextField";
import { selectMaintenanceCategories } from "store/slices/configSlice";
import ContractAutocomplete, {
  ContractAutoCompleteProps,
} from "components/autocomplete/ContractAutoComplete";
import NumberIncrementor from "components/NumberIncrementor";
import { WebWOQueryResponse } from "store/services/workOrder";
import {
  useGetAssetContractHistoryQuery,
  useGetAssetsByContractIdQuery,
} from "store/services/asset";
import { useGetContractByIdQuery } from "store/services/contract";
import { useAppDispatch } from "store/store";
import { setGlobalMessage } from "store/slices/systemSlice";
import { byIds } from "store/sliceUtils";

interface WorkOrderRowProps {
  label: string;
  input?: ReactNode;
}

const WorkOrderRow: FC<WorkOrderRowProps> = ({ label, input }) => {
  return (
    <Grid container alignItems="center" mb={1.5} minHeight="40px">
      <Grid item xs={4} sx={{ color: "text.secondary" }}>
       <Typography>{label}</Typography>
      </Grid>
      <Grid
        item
        xs={8}
        sx={{ textAlign: typeof input === "string" ? "right" : "" }}
      >
        {input}
      </Grid>
    </Grid>
  );
};

interface Props {
  mode: "create" | "edit";
  isInspection?: boolean;
}

const WorkOrderForm: React.FC<Props> = ({ isInspection, mode = "edit" }) => {
  const { values, errors, touched, setFieldValue, handleChange, handleBlur } =
    useFormikContext<WebWOQueryResponse>();

  const dispatch = useAppDispatch();
  const isStandardWorkOrder = !Boolean(isInspection || values.inspection);
  const users = useSelector(selectUsers);
  const { data: contractAssets = [] } = useGetAssetsByContractIdQuery(values?.contract?._id || '', {skip: !values.contract});
  const assetsHash = byIds(contractAssets);
  const maintenanceCategories = useSelector(selectMaintenanceCategories);

  const { data: selectedContract } = useGetContractByIdQuery(
    values.contract?._id || "",
    { skip: !values.contract?._id }
  );
  2;
  const [searchByAssetNum, setSearchByAssetNum] = useState(false);

  const {
    data: assetContractHistory,
    isLoading: isLoadingAssetContractHistory,
  } = useGetAssetContractHistoryQuery(
    { assetId: values.asset?._id, limit: 5 },
    { skip: !values.asset }
  );


  const handleContractChange: ContractAutoCompleteProps["onChange"] = (
    _,
    contract
  ) => {
    const isContract = IsTypeOfContract(contract);
    if (isContract) {
      setFieldValue("contract", {
        _id: contract._id,
        customerName: contract.customerName,
        projectNumber: contract.projectNumber,
      });
    } else if (!contract) {
      setFieldValue("contract", null);
    }
  };

  const handleAssetChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: NonNullable<string | Asset> | (string | Asset)[] | null
  ) => {
    const isAsset = isOfDocumentType<Asset>(value);
    if (!isAsset) {
      setFieldValue("asset", null);
      return;
    }

    const assetIsOnContract = Boolean(assetsHash[value._id])

    if (selectedContract && !assetIsOnContract) {
      dispatch(
        setGlobalMessage({
          messageText: "Asset is not on the selected contract",
          severity: "error",
          show: true,
        })
      );
      return;
    }

    setFieldValue("asset", {
      _id: value._id,
      serialNumber: value.serialNumber,
      assetNumber: value.assetNumber,
    });
  };

  const getAssetsOptions = () => {
    if (values.contract?._id) {
      return contractAssets
    } else {
      return undefined
    }
  };

  return (
    <Card sx={{ p: 3 }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        {isStandardWorkOrder && (
          <>
            <WorkOrderRow
              label="Title"
              input={
                <FastField name="title">
                  {({ field }: FastFieldProps) => (
                    <TextField
                      {...field}
                      size="small"
                      autoComplete="off"
                      fullWidth
                      error={Boolean(touched.title && errors.title)}
                      inputProps={{
                        "data-testid": "title",
                      }}
                    />
                  )}
                </FastField>
              }
            />

            <WorkOrderRow label="Description" />
            <FastField name="description">
              {({ field }: FastFieldProps) => (
                <TextField
                  {...field}
                  multiline
                  minRows={3}
                  size="small"
                  autoComplete="off"
                  fullWidth
                  error={Boolean(touched.description && errors.description)}
                  inputProps={{
                    "data-testid": "description",
                  }}
                  sx={{
                    mb: 3,
                    mt: -1,
                  }}
                />
              )}
            </FastField>
          </>
        )}

        <WorkOrderRow
          label="Priority"
          input={
            <PrimitiveSelect
              name="priority"
              size="small"
              value={values.priority}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              inputProps={{
                "data-testid": "priority",
              }}
              options={Object.values(Priority)}
            />
          }
        />

        {mode === "edit" && values.tasks && (
          <ToggleButtonGroup
            exclusive
            fullWidth
            value={values.status}
            onChange={(_, value) => value && setFieldValue("status", value)}
            sx={{ mb: 3 }}
            size="small"
            color="primary"
          >
            {Object.values(WorkOrderStatus).map((status) => (
              <ToggleButton
                data-testid="status"
                sx={{ minWidth: 100 }}
                value={status}
                key={status}
                disabled={
                  status === WorkOrderStatus.COMPLETE &&
                  !values.tasks?.every((task) => task.complete)
                }
              >
                {status}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        )}

        <WorkOrderRow
          label="Assigned to"
          input={
            <UserAutoComplete
              name="assignedTo"
              size="small"
              value={values.assignedTo ? users[values.assignedTo._id] : null}
              onChange={(_, user) => {
                if (isOfDocumentType<UserData>(user)) {
                  setFieldValue("assignedTo", {
                    _id: user?._id,
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                  });
                } else {
                  setFieldValue("assignedTo", null);
                }
              }}
              onBlur={handleBlur}
              fullWidth
              data-testid="assignedTo"
            />
          }
        />

        {isStandardWorkOrder && (
          <>
            <WorkOrderRow label="Tasks" />
            <Tasks mode={mode} />
          </>
        )}

        <ToggleButtonGroup
          exclusive
          value={String(searchByAssetNum)}
          onChange={(_, value) => setSearchByAssetNum(value === "true")}
          size="small"
          color="primary"
          fullWidth
          sx={{ mb: 2 }}
        >
          <ToggleButton value="false">Serial #</ToggleButton>
          <ToggleButton value="true">Asset#</ToggleButton>
        </ToggleButtonGroup>

        {searchByAssetNum ? (
          <WorkOrderRow
            label="Asset #"
            input={
              <AssetAutoComplete
                name="asset"
                size="small"
                value={values.asset ? assetsHash[values.asset._id] : null}
                onChange={handleAssetChange}
                onBlur={handleBlur}
                error={Boolean(touched.asset && errors.asset)}
                fullWidth
                useAssetNumber
                data-testid="asset"
                options={getAssetsOptions()}
                disabled={
                  (mode === "edit" &&
                    [
                      WorkOrderStatus.COMPLETE,
                      WorkOrderStatus.CANCELLED,
                    ].includes(values.status)) ||
                  Boolean(values.parent)
                }
              />
            }
          />
        ) : (
          <WorkOrderRow
            label="Serial #"
            input={
              <AssetAutoComplete
                name="asset"
                size="small"
                value={values.asset?._id ? assetsHash[values.asset._id] : null}
                onChange={handleAssetChange}
                onBlur={handleBlur}
                error={Boolean(touched.asset && errors.asset)}
                fullWidth
                data-testid="asset"
                options={getAssetsOptions()}
                disabled={
                  (mode === "edit" &&
                    [
                      WorkOrderStatus.COMPLETE,
                      WorkOrderStatus.CANCELLED,
                    ].includes(values.status)) ||
                  Boolean(values.parent)
                }
              />
            }
          />
        )}
        {/* Replace with contract autocomplete */}
        <WorkOrderRow
          label="Contract"
          input={
            <ContractAutocomplete
              onChange={handleContractChange}
              value={
                values.contract
                  ? assetContractHistory?.contracts.find(
                      (contract) => contract._id === values.contract?._id
                    )
                  : null
              }
              size="small"
              options={
                values.asset?._id ? assetContractHistory?.contracts : undefined
              }
              loading={isLoadingAssetContractHistory}
              disabled={mode === "edit"}
            />
          }
        />

        <WorkOrderRow
          label="Created on"
          input={toDDMMMYYYY(dateFromMMDDYYYY(values.createdOn))}
        />

        <WorkOrderRow
          label="Created by"
          input={getFullName(values.createdBy) || "N/A"}
        />

        <WorkOrderRow
          label="Due Date"
          input={
            <DateSelector
              onChange={(date) =>
                date && setFieldValue("dueDate", toMMDDYYYY(new Date(date)))
              }
              value={dateFromMMDDYYYY(values.dueDate)}
              textFieldProps={{
                onBlur: handleBlur,
                fullWidth: true,
                size: "small",
                error: Boolean(touched.dueDate && errors.dueDate),
              }}
            />
          }
        />

        {isStandardWorkOrder && values.tasks && (
          <>
            <WorkOrderRow
              label="Category"
              input={
                <PrimitiveSelect
                  name="category"
                  size="small"
                  value={values.category}
                  onChange={({ target }) => {
                    if (target) {
                      setFieldValue("category", target.value);
                      setFieldValue("subcategory", "");
                    }
                  }}
                  onBlur={handleBlur}
                  fullWidth
                  inputProps={{
                    "data-testid": "category",
                  }}
                  error={Boolean(touched.category && errors.category)}
                  options={Object.keys(maintenanceCategories)}
                />
              }
            />

            <WorkOrderRow
              label="Subcategory"
              input={
                <PrimitiveSelect
                  name="subcategory"
                  size="small"
                  value={values.subcategory}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  inputProps={{
                    "data-testid": "subcategory",
                  }}
                  error={Boolean(touched.subcategory && errors.subcategory)}
                  options={maintenanceCategories[values.category || ""]}
                />
              }
            />

            <WorkOrderRow
              label="Est. Hours"
              input={
                <FastField name="estimatedHours">
                  {({ field, form }: FastFieldProps) => (
                    <NumberTextField
                      {...field}
                      onChange={(event) => {
                        const { value } = event.target;
                        form.setFieldValue(
                          "estimatedHours",
                          !value && !isNaN(Number(value)) ? null : Number(value)
                        );
                      }}
                      size="small"
                      fullWidth
                      error={Boolean(
                        touched.estimatedHours && errors.estimatedHours
                      )}
                      inputProps={{
                        "data-testid": "estimatedHours",
                      }}
                    />
                  )}
                </FastField>
              }
            />
            <NumberIncrementor
              value={values.estimatedHours || 0}
              onClick={(number) => setFieldValue("estimatedHours", number)}
              sx={{ mb: 3 }}
            />

            <WorkOrderRow
              label="Est. Materials Cost"
              input={
                <Field name="estimatedMaterials">
                  {({ field, form }: FastFieldProps) => (
                    <CurrencyTextField
                      {...field}
                      fullWidth
                      value={field.value}
                      onChange={(event) => {
                        const { value } = event.target;
                        form.setFieldValue(
                          "estimatedMaterials.amount",
                          !value && !isNaN(Number(value)) ? null : Number(value)
                        );
                      }}
                      onCurrencyChange={(event) => {
                        event.target.value &&
                          form.setFieldValue(
                            "estimatedMaterials.currency",
                            event.target.value
                          );
                      }}
                      error={Boolean(
                        touched.estimatedMaterials && errors.estimatedMaterials
                      )}
                      inputProps={{
                        "data-testid": "estimatedMaterials",
                      }}
                      size="small"
                    />
                  )}
                </Field>
              }
            />

            {mode === "edit" && (
              <>
                <WorkOrderRow
                  label="Actual Hours"
                  input={
                    <FastField name="actualHoursToComplete">
                      {({ field }: FastFieldProps) => (
                        <NumberTextField
                          {...field}
                          size="small"
                          value={field.value}
                          fullWidth
                          inputProps={{
                            "data-testid": "actualHoursToComplete",
                          }}
                          error={Boolean(
                            touched.actualHoursToComplete &&
                              errors.actualHoursToComplete
                          )}
                        />
                      )}
                    </FastField>
                  }
                />
                <NumberIncrementor
                  value={values.actualHoursToComplete || 0}
                  onClick={(number) =>
                    setFieldValue("actualHoursToComplete", number)
                  }
                  sx={{ mb: 3 }}
                />

                <WorkOrderRow
                  label="Actual Materials Cost"
                  input={
                    <Field name="actualMaterialsToComplete">
                      {({ field, form }: FastFieldProps) => (
                        <CurrencyTextField
                          {...field}
                          fullWidth
                          value={field.value}
                          onChange={(event) => {
                            form.setFieldValue(
                              "actualMaterialsToComplete.amount",
                              Number(event.target.value) || undefined
                            );
                          }}
                          onCurrencyChange={(event) => {
                            event.target.value &&
                              form.setFieldValue(
                                "actualMaterialsToComplete.currency",
                                event.target.value
                              );
                          }}
                          error={Boolean(
                            touched.actualMaterialsToComplete &&
                              errors.actualMaterialsToComplete
                          )}
                          inputProps={{
                            "data-testid": "actualMaterialsToComplete",
                          }}
                          size="small"
                        />
                      )}
                    </Field>
                  }
                />
              </>
            )}
          </>
        )}
        <WorkOrderRow
          label="PO Number"
          input={
            <FastField name="purchaseOrder.poNumber">
              {({ field, form }: FastFieldProps) => (
                <TextField
                  {...field}
                  size="small"
                  autoComplete="off"
                  fullWidth
                  onChange={(event) => {
                    form.setFieldValue(
                      "purchaseOrder.poNumber",
                      event.target.value
                    );
                  }}
                  error={Boolean(
                    getIn(touched, "purchaseOrder.poNumber") &&
                      getIn(errors, "purchaseOrder.poNumber")
                  )}
                  inputProps={{
                    "data-testid": "purchaseOrder.poNumber",
                  }}
                />
              )}
            </FastField>
          }
        />
        <WorkOrderRow
          label="PO Amount"
          input={
            <Field name="purchaseOrder.amount">
              {({ field, form }: FastFieldProps) => (
                <CurrencyTextField
                  {...field}
                  fullWidth
                  value={field.value}
                  onChange={(event) => {
                    form.setFieldValue(
                      "purchaseOrder.amount.amount",
                      Number(event.target.value) || undefined
                    );
                  }}
                  onCurrencyChange={(event) => {
                    event.target.value &&
                      form.setFieldValue(
                        "purchaseOrder.amount.currency",
                        event.target.value
                      );
                  }}
                  error={Boolean(
                    getIn(touched, "purchaseOrder.amount") &&
                      getIn(errors, "purchaseOrder.amount")
                  )}
                  inputProps={{
                    "data-testid": "purchaseOrder.amount",
                  }}
                  size="small"
                />
              )}
            </Field>
          }
        />

        {isStandardWorkOrder && values.tasks && (
          <>
            <WorkOrderRow
              label="Billable to Client"
              input={
                <ToggleButtonGroup
                  exclusive
                  fullWidth
                  value={String(values.billableToClient)}
                  onChange={(_, value) =>
                    value && setFieldValue("billableToClient", value)
                  }
                  size="small"
                  color="primary"
                >
                  <ToggleButton
                    data-testid="not-billable"
                    sx={{ minWidth: 100 }}
                    value="false"
                  >
                    No
                  </ToggleButton>
                  <ToggleButton
                    data-testid="is-billable"
                    sx={{ minWidth: 100 }}
                    value="true"
                  >
                    Yes
                  </ToggleButton>
                </ToggleButtonGroup>
              }
            />

            <WorkOrderRow
              label="Must be Fixed Before Renting?"
              input={
                <ToggleButtonGroup
                  exclusive
                  fullWidth
                  value={String(values.isBlocking)}
                  onChange={(_, value) =>
                    value && setFieldValue("isBlocking", value)
                  }
                  size="small"
                  color="primary"
                >
                  <ToggleButton sx={{ minWidth: 100 }} value="false">
                    No
                  </ToggleButton>
                  <ToggleButton sx={{ minWidth: 100 }} value="true">
                    Yes
                  </ToggleButton>
                </ToggleButtonGroup>
              }
            />
          </>
        )}
      </Grid>
    </Card>
  );
};
export default WorkOrderForm;
