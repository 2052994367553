import DeleteForever from '@mui/icons-material/DeleteForever';
import BillOfLadingIcon from '@mui/icons-material/AssignmentOutlined';
import { IconButton, ButtonGroup, ButtonProps, Button, Box } from '@mui/material';
import ConfirmationModal from 'components/ConfirmationModal';
import DateSelector from 'components/DateSelector';
import usePermissions, { ActionType, FeatureType, UIActionType } from 'hooks/usePermissions';
import React, { Dispatch, SetStateAction } from 'react'
import { useSelector } from 'react-redux';
import Movement, { MovementStatus } from 'store/models/Movement';
import { setMovementContractId, setCurrentBillOfLadingId, setBillOfLadingMovementId, selectCurrentContractId } from 'store/slices/contractSlice';
import { setModalMode, simpleGlobalMessage } from 'store/slices/systemSlice';
import { useAppDispatch } from 'store/store';
import { isValid } from 'date-fns';
import { MMDDYYYY, dateFromMMDDYYYY, isMMDDYYYY, toMMDDYYYY } from 'utils/util';
import { useGetContractByIdQuery, useUpdateContractMovementsMutation, useUpdateMovementStatusMutation } from 'store/services/contract';
import { PickerChangeHandlerContext, DateValidationError } from '@mui/x-date-pickers-pro';


interface Props {
  movement: Movement;
  disabled?: boolean;
  changeIsSubmitting: Dispatch<SetStateAction<boolean>>;
}

const ContractMovement: React.FC<Props> = ({
  movement,
  disabled,
  changeIsSubmitting
}) => {
  const currentContractId = useSelector(selectCurrentContractId);
  const {data: currentContract} = useGetContractByIdQuery(currentContractId || "", {skip: !currentContractId});
  const checkBOLPermissions = usePermissions(FeatureType.BILL_OF_LADING);
  const userCanViewBOLs = checkBOLPermissions(ActionType.READ);
  const checkMovementPermissions = usePermissions(FeatureType.MOVEMENT);
  const userCanEditMovements = checkMovementPermissions(ActionType.UPDATE);
  const [updateContractMovements] = useUpdateContractMovementsMutation();
  const [updateMovementStatusMutation] = useUpdateMovementStatusMutation()

  const dispatch = useAppDispatch();

  const viewBillOfLading = () => {
    const userCanReadBOLs = checkBOLPermissions(
      ActionType.READ,
      UIActionType.ONCLICK
    );
    if (!userCanReadBOLs) return;
    dispatch(setMovementContractId(currentContract?._id));
    dispatch(setCurrentBillOfLadingId(movement.billOfLading));
    dispatch(
      setModalMode(
        movement.billOfLading ? "billOfLadingViewEdit" : "billOfLadingCreate"
      )
    );
    dispatch(setBillOfLadingMovementId(movement._id));
  };


  const removeMovement = () => {
    const userCanEditMovements = checkMovementPermissions(
      ActionType.UPDATE,
      UIActionType.ONCLICK
    );
    if (!userCanEditMovements || !currentContract) return;

    if (movement.status === MovementStatus.completed) {
      dispatch(simpleGlobalMessage("You cannot remove COMPLETED movements"));
      return;
    }

    changeIsSubmitting(true);

    updateContractMovements({
      contractId: currentContract._id,
      movementUpdates: [movement],
      action: "Remove",
    })
      .unwrap()
      .then(({ contract, assets }) => {
        dispatch(
          simpleGlobalMessage("Successfully removed movement", "success")
        );
      })
      .finally(() => {
        changeIsSubmitting(false);
      });
  };

  const handleDateOfMoveInputChange = (value: Date | null, context: PickerChangeHandlerContext<DateValidationError>) => {
    if (!currentContract || !value) return;

    if (isValid(new Date(value))) {
      updateContractMovements({
        contractId: currentContract._id,
        movementUpdates: [{ ...movement, dateOfMove: toMMDDYYYY(value) }],
        action: "Update",
      })
        .unwrap()
        .catch(() => {
          dispatch(
            simpleGlobalMessage("Failed to update movement date", "error")
          );
        });
    }
  };

  const handleDateOfMoveAccept = (date: unknown) => {
    if (!currentContract) return;
    const newDate = date as Date;
    if (newDate && isValid(new Date(newDate))) {
      updateContractMovements({
        contractId: currentContract._id,
        movementUpdates: [{ ...movement, dateOfMove: toMMDDYYYY(newDate) }],
        action: "Update",
      })
        .unwrap()
        .catch(() => {
          dispatch(
            simpleGlobalMessage("Failed to update movement date", "error")
          );
        });
    }
  };

  function updateMovementStatus(status: MovementStatus) {
    if (!currentContract || status === movement.status) return;
    if (currentContract.status === "CONCLUDED") {
      dispatch(
        simpleGlobalMessage(
          "You cannot change the status of movements on concluded contracts"
        )
      );
      return;
    }
    changeIsSubmitting(true);
    updateMovementStatusMutation({
      contractId: currentContract._id,
      movementId: movement._id,
      newStatus: status,
    })
      .unwrap()
      .then(({ contract, assets }) => {
        dispatch(
          simpleGlobalMessage("Successfully updated movement status", "success")
        );
        changeIsSubmitting(false);
      })
      .catch(() => {
        dispatch(
          simpleGlobalMessage("Failed to update movement status", "error")
        );
      })
  }

  const isDisabled = !userCanEditMovements || disabled
  

  return (
    <Box
      key={movement._id}
      sx={{
        display: "grid",
        justifyItems: "start",
        alignItems: "center",
        gridTemplateColumns: "auto 40px 40px",
        rowGap: 1,
        gridTemplateAreas: `
                "movementStatus movementStatus type"
                "dateOfMove remove billOfLading"`,
        mb: 2,
        "&:not(:first-of-type)": {
          mt: 3,
        },
      }}
    >
      <DateSelector
        disabled={isDisabled}
        value={dateFromMMDDYYYY(movement.dateOfMove)}
        className="dateOfMove"
        onChange={handleDateOfMoveInputChange}
        onAccept={handleDateOfMoveAccept}
        textFieldProps={{
          size: "small",
          fullWidth: true,
        }}
      />
      <Box
        sx={(theme) => ({
          gridArea: "type",
          justifySelf: "center",
          fontSize: theme.typography.body2,
        })}
      >
        {movement.type.substring(0, 3)}:{" "}
      </Box>

      <ConfirmationModal
        handleConfirmation={() => removeMovement()}
        message="Are you sure you want to delete this movement?"
        sx={{
          gridArea: "remove",
        }}
        disabled={isDisabled}
      >
        <IconButton
          color="primary"
          disabled={isDisabled}
        >
          <DeleteForever />
        </IconButton>
      </ConfirmationModal>
      <Box sx={{ gridArea: "movementStatus" }}>
        <ButtonGroup
          size="small"
          disableElevation
          disabled={isDisabled}
        >
          {Object.values(MovementStatus).map((status) => {
            const color: ButtonProps["color"] =
              movement.status === status ? "primary" : "inherit";

            return (
              <Button
                disabled={disabled}
                key={status}
                color={color}
                onClick={() => updateMovementStatus(status)}
              >
                {status}
              </Button>
            );
          })}
        </ButtonGroup>
      </Box>
      <IconButton
        onClick={viewBillOfLading}
        disabled={!userCanViewBOLs}
        color="primary"
        sx={{
          gridArea: "billOfLading",
        }}
      >
        <BillOfLadingIcon
          color={movement.billOfLading ? "primary" : "disabled"}
        />
      </IconButton>
    </Box>
  );
};

export default ContractMovement