import { Autocomplete, Box, TextField } from "@mui/material";
import { GridFilterInputValueProps, GridFilterOperator, useGridRootProps } from "@mui/x-data-grid-pro";
import { FC, useEffect, useRef, useState } from "react";

const multiSelector: FC<GridFilterInputValueProps> = ({
  item,
  applyValue,
  focusElementRef = null,
  apiRef
}) => {
  const rootProps = useGridRootProps();
  const column = apiRef.current.getColumn(item.field);
  const filterTimeout = useRef<any>();
  const [filterValueState, setFilterValueState] = useState<string[]>(item.value ?? []);
  const [applying, setIsApplying] = useState(false);
  const [initField, setInitField] = useState(() => item.field)
  const inputRef = useRef<HTMLInputElement | null>(null);

  const uniqueValues = Array.from(new Set(rootProps.rows.map(row => String(apiRef.current.getRowFormattedValue(row, column))))).sort(); // Replace columnValue with the actual field name

  useEffect(() => {
    if (inputRef.current) {
      const { parentElement } = inputRef.current;
      if (parentElement) {
        parentElement.style.width = '100%';
      }
    }
  }, []);


  useEffect(
    () => () => {
      clearTimeout(filterTimeout.current);
    },
    []
  );

  useEffect(() => {
    setFilterValueState(item.value ?? []);
  }, [item.value])

  
  useEffect(() => {
    if (item.field !== initField) {
        setInitField(item.field)
        setFilterValueState([]);
        applyValue({ ...item, value: [] });
    }
  }, [item.field]);

  const updateFilterValue = (statues: string[]) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState(statues);
    
    setIsApplying(true);
    filterTimeout.current = setTimeout(() => {
      setIsApplying(false);
      applyValue({ ...item, value: statues });
    }, rootProps.filterDebounceMs);
  };

  return (
    <Box
      sx={{
        width: 1,
        flex: 1,
        display: "flex",
        alignItems: "center",
      }}
      ref={inputRef}
    >
      <Autocomplete
        key={item.field}
        multiple
        value={filterValueState}
        onChange={(_, value) => updateFilterValue(value as unknown as string[])}
        loading={applying}
        size="small"
        sx={{
          "& .MuiAutocomplete-endAdornment": {
            marginLeft: "20px!important",
          },
          "* .MuiInputBase-input": {
            marginX: "20px",
          },
        }}
        options={uniqueValues}
        renderInput={(params) => (
          <TextField
            {...params}
            label="From"
            inputRef={focusElementRef}
            variant="standard"
            size="small"
            fullWidth
            sx={{ minWidth: 200 }}
          />
        )}
      />
    </Box>
  );
};

export const notInOperator: (filterName: string) => GridFilterOperator<any, string> = (filterName: string) => ({
  label: 'Not In',
  value: filterName,
  getApplyFilterFn: (filterItem) => {
    if (!Array.isArray(filterItem.value) || !filterItem.value.length) {
      return null;
    }
    return (formattedValue) => formattedValue !== null && !filterItem.value.includes(formattedValue);
  },
  InputComponent: multiSelector,
});

export const inOperator: (filterName: string) => GridFilterOperator<any, string> = (filterName: string) => ({
  label: 'In',
  value: filterName,
  getApplyFilterFn: (filterItem) => {
    if (!Array.isArray(filterItem.value) || !filterItem.value.length) {
      return null;
    }
    return (formattedValue) => formattedValue !== null && filterItem.value.includes(formattedValue);
  },
  InputComponent: multiSelector,
});