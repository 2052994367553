import React, { FC, MouseEvent, ReactNode } from "react";
import { Asset } from "store/models/Asset";
import {
  Box,
  Card,
  Checkbox,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import Scrollbar from "components/Scrollbar";
import { useSelector } from "react-redux";
import { ConditionSelect, PrimitiveSelect } from "components/select";
import {
  FastField,
  FastFieldProps,
  Field,
  FieldProps,
  useFormikContext,
} from "formik";
import { BranchAutoComplete, YardAutoComplete } from "components/autocomplete";
import { Branch } from "store/models/Branch";
import { Yard } from "store/models/Yard";
import { selectBranches, selectYards } from "store/slices/optionSlice";
import {
  selectCategories,
  selectSubCategories,
  selectBuildingCodes,
  selectAirConditionTypes,
  selectCladding,
  selectElectrical,
  selectFuelTypes,
  selectFloorSytles,
  selectStructuralLimits,
  selectPartitions,
  selectBranding,
  selectWashRoomCounts,
  selectSizeCodes,
} from "store/slices/configSlice";
import DateSelector from "components/DateSelector";
import { dateFromMMDDYYYY, toMMDDYYYY } from "utils/util";
import PercentageTextField from "components/PercentageTextField";
import CurrencyTextField from "components/CurrencyTextField";
import { reserveableAssetStatuses } from "store/api/asset";
import NumberTextField from "components/NumberTextField";
import usePermissions, { ActionType, FeatureType } from "hooks/usePermissions";
import { AssetStatus } from "store/models/AssetStatus";
import { selectLoggedInUser } from "store/slices/systemSlice";
import Report from "@mui/icons-material/Report";
import useSettings from "hooks/useSettings";
import FastFtInchInputs from "components/formik/FastFtInchInputs";
import { useUpdateAssetStatusMutation } from "store/services/asset";
import { isValid } from "date-fns";

interface AssetRowProps {
  label: string;
  input?: ReactNode;

}

const AssetRow: FC<AssetRowProps> = ({ label, input }) => {
  return (
    <Grid container alignItems="center" my={1}>
      <Grid item xs={4} sx={{ color: "text.secondary" }}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item xs={8} px={2}>
        {input}
      </Grid>
    </Grid>
  );
};

interface Props {
  isCreating?: boolean;
}

const AssetForm: React.FC<Props> = ({ isCreating }) => {
  const theme = useTheme();
  const { themeMode } = useSettings();
  const sizeCodes = useSelector(selectSizeCodes);
  const branches = useSelector(selectBranches);
  const yards = useSelector(selectYards);
  const subCategories = useSelector(selectSubCategories);
  const categories = useSelector(selectCategories);
  const buildingCodes = useSelector(selectBuildingCodes);
  const aidConditionTypes = useSelector(selectAirConditionTypes);
  const cladding = useSelector(selectCladding);
  const electrical = useSelector(selectElectrical);
  const fuelTypes = useSelector(selectFuelTypes);
  const floorStyles = useSelector(selectFloorSytles);
  const loggedInUser = useSelector(selectLoggedInUser);
  const structuralLimits = useSelector(selectStructuralLimits);
  const partitions = useSelector(selectPartitions);
  const branding = useSelector(selectBranding);
  const numberOfWashrooms = useSelector(selectWashRoomCounts);
  const { values, errors, touched, setFieldValue } = useFormikContext<Asset>();
  const checkAssetsPermissions = usePermissions(FeatureType.ASSET);
  const userCanEditAssets = checkAssetsPermissions(ActionType.UPDATE);
  const updateableStatuses: AssetStatus[] = [
    "AVAILABLE",
    "IN MAINTENANCE",
    "MAINTENANCE NEEDED",
    "NOT RENTABLE",
  ];

  const [updateAssetStatus] = useUpdateAssetStatusMutation();

  function getStatusOptions() {
    switch (true) {
      case isCreating:
        return reserveableAssetStatuses;
      case updateableStatuses.includes(values.status):
        return updateableStatuses;
      default:
        return [values.status];
    }
  }

  function handleStatusChange(
    _: MouseEvent<HTMLElement, globalThis.MouseEvent>,
    status: AssetStatus
  ) {
    if (!status) {
      return;
    }

    if (isCreating) {
      setFieldValue("status", status, false);
    } else {
      updateAssetStatus({
        asset: {
          _id: values._id,
          version: values.version,
          status,
        },
        reason: `Asset Manually Updated by ${loggedInUser?.email}`,
      })
        .unwrap()
    }
  }
  const formErrors = Object.keys(errors);
  return (
    <Scrollbar>
      {Boolean(formErrors.length) && (
        <Box
          sx={{
            backgroundColor:
              themeMode === "dark"
                ? theme.palette.error.light
                : theme.palette.error.lighter,
            my: 1,
            padding: 1,
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Report color="error" sx={{ mr: 1 }} />
          <Typography color={theme.palette.error.dark} fontSize={14}>
            {`Asset form has the following errors: ${formErrors.join(", ")}`}
          </Typography>
        </Box>
      )}
      {userCanEditAssets && (
        <ToggleButtonGroup
          size="small"
          color="primary"
          sx={{ marginBottom: 1 }}
          fullWidth
          value={values.status}
          exclusive
          onChange={handleStatusChange}
        >
          {getStatusOptions().map((statusOption) => (
            <ToggleButton
              key={statusOption}
              sx={{ fontSize: 10, whiteSpace: "nowrap" }}
              value={statusOption}
            >
              {statusOption}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
      <Card sx={{ p: 3 }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          sx={{ pl: 3 }}
        >
          <AssetRow
            label="Asset #"
            input={
              <FastField name="assetNumber">
                {({ field }: FastFieldProps) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={Boolean(touched.assetNumber && errors.assetNumber)}
                    inputProps={{
                      "data-testid": "assetNumber",
                    }}
                    size="small"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Serial #"
            input={
              <FastField name="serialNumber">
                {({ field }: FastFieldProps) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={Boolean(touched.serialNumber && errors.serialNumber)}
                    inputProps={{
                      "data-testid": "serialNumber",
                    }}
                    size="small"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Branch"
            input={
              <FastField name="branch">
                {({ field, form }: FastFieldProps) => (
                  <BranchAutoComplete
                    {...field}
                    value={field.value && branches[field.value]}
                    size="small"
                    onChange={(_, value) =>
                      form.setFieldValue(
                        "branch",
                        (value as Branch)?._id || null
                      )
                    }
                    fullWidth
                    error={Boolean(touched.branch && errors.branch)}
                    data-testid="branch"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="In Production"
            input={
              <Checkbox
                checked={values.inProduction}
                onClick={() =>
                  setFieldValue("inProduction", !values.inProduction)
                }
              />
            }
          />

          <AssetRow
            label="Yard"
            input={
              <FastField name="yard">
                {({ field, form }: FastFieldProps) => (
                  <YardAutoComplete
                    {...field}
                    value={field.value && yards[field.value]}
                    size="small"
                    onChange={(_, value) =>
                      form.setFieldValue("yard", (value as Yard)?._id || null)
                    }
                    fullWidth
                    error={Boolean(touched.yard && errors.yard)}
                    data-testid="yard"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Yard Position"
            input={
              <FastField name="yardPosition">
                {({ field }: FastFieldProps) => (
                  <TextField
                    {...field}
                    size="small"
                    fullWidth
                    error={Boolean(touched.yardPosition && errors.yardPosition)}
                    inputProps={{
                      "data-testid": "yardPosition",
                    }}
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Category"
            input={
              <FastField name="category">
                {({ field }: FastFieldProps) => (
                  <PrimitiveSelect
                    {...field}
                    size="small"
                    fullWidth
                    error={Boolean(touched.category && errors.category)}
                    options={categories}
                    data-testid="category"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Sub-Category"
            input={
              <FastField name="subCategory">
                {({ field }: FastFieldProps) => (
                  <PrimitiveSelect
                    size="small"
                    {...field}
                    fullWidth
                    error={Boolean(touched.subCategory && errors.subCategory)}
                    options={subCategories}
                    data-testid="subCategory"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Condition"
            input={
              <FastField name="condition">
                {({ field }: FastFieldProps) => (
                  <ConditionSelect
                    size="small"
                    {...field}
                    value={values.condition || ""}
                    fullWidth
                    error={Boolean(touched.condition && errors.condition)}
                    data-testid="condition"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Branding"
            input={
              <FastField name="branding">
                {({ field }: FastFieldProps) => (
                  <PrimitiveSelect
                    size="small"
                    {...field}
                    fullWidth
                    error={Boolean(touched.branding && errors.branding)}
                    options={branding}
                    data-testid="branding"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Manufacturer"
            input={
              <FastField name="manufacturer">
                {({ field }: FastFieldProps) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={Boolean(touched.manufacturer && errors.manufacturer)}
                    inputProps={{
                      "data-testid": "manufacturer",
                    }}
                    size="small"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Hours"
            input={
              <FastField name="hrs">
                {({ field }: FastFieldProps) => (
                  <TextField
                    {...field}
                    type="number"
                    fullWidth
                    error={Boolean(touched.hrs && errors.hrs)}
                    inputProps={{
                      "data-testid": "hrs",
                    }}
                    size="small"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Notes"
            input={
              <FastField name="notes">
                {({ field }: FastFieldProps) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    maxRows={4}
                    error={Boolean(touched.notes && errors.notes)}
                    inputProps={{
                      "data-testid": "notes",
                    }}
                    size="small"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Year Manufactured"
            input={
              <FastField name="yearOfManufacture">
                {({ field }: FastFieldProps) => (
                  <TextField
                    {...field}
                    onChange={(e) =>
                      setFieldValue(
                        "yearOfManufacture",
                        e.currentTarget.value.replace(/\D/, "")
                      )
                    }
                    fullWidth
                    error={Boolean(
                      touched.yearOfManufacture && errors.yearOfManufacture
                    )}
                    inputProps={{
                      "data-testid": "yearOfManufacture",
                    }}
                    size="small"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Building Code"
            input={
              <FastField name="buildingCode">
                {({ field }: FastFieldProps) => (
                  <PrimitiveSelect
                    size="small"
                    {...field}
                    value={values.buildingCode}
                    fullWidth
                    error={Boolean(touched.buildingCode && errors.buildingCode)}
                    options={buildingCodes}
                    data-testid="buildingCode"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="A/C Type"
            input={
              <FastField name="acType">
                {({ field }: FastFieldProps) => (
                  <PrimitiveSelect
                    size="small"
                    {...field}
                    fullWidth
                    error={Boolean(touched.acType && errors.acType)}
                    options={aidConditionTypes}
                    data-testid="acType"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Heating Type"
            input={
              <FastField name="heatingType">
                {({ field }: FastFieldProps) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={Boolean(touched.heatingType && errors.heatingType)}
                    inputProps={{
                      "data-testid": "heatingType",
                    }}
                    size="small"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Cladding"
            input={
              <FastField name="cladding">
                {({ field }: FastFieldProps) => (
                  <PrimitiveSelect
                    size="small"
                    {...field}
                    fullWidth
                    error={Boolean(touched.cladding && errors.cladding)}
                    options={cladding}
                    data-testid="cladding"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Electrical"
            input={
              <FastField name="electrical">
                {({ field }: FastFieldProps) => (
                  <PrimitiveSelect
                    size="small"
                    {...field}
                    fullWidth
                    error={Boolean(touched.electrical && errors.electrical)}
                    options={electrical}
                    data-testid="electrical"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Fuel Type"
            input={
              <FastField name="fuelType">
                {({ field }: FastFieldProps) => (
                  <PrimitiveSelect
                    size="small"
                    {...field}
                    fullWidth
                    error={Boolean(touched.fuelType && errors.fuelType)}
                    options={fuelTypes}
                    data-testid="fuelType"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Floor Style"
            input={
              <FastField name="floorStyle">
                {({ field }: FastFieldProps) => (
                  <PrimitiveSelect
                    size="small"
                    {...field}
                    fullWidth
                    error={Boolean(touched.floorStyle && errors.floorStyle)}
                    options={floorStyles}
                    data-testid="floorStyle"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Size Code"
            input={
              <FastField name="sizeCode">
                {({ field }: FastFieldProps) => (
                  <PrimitiveSelect
                    size="small"
                    {...field}
                    fullWidth
                    error={Boolean(touched.sizeCode && errors.sizeCode)}
                    options={sizeCodes}
                    data-testid="sizeCode"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow label="Height" input={<FastFtInchInputs name="height" />} />

          <AssetRow
            label="Structural Limit"
            input={
              <FastField name="structuralLimit">
                {({ field }: FastFieldProps) => (
                  <PrimitiveSelect
                    size="small"
                    {...field}
                    fullWidth
                    error={Boolean(
                      touched.structuralLimit && errors.structuralLimit
                    )}
                    options={structuralLimits}
                    data-testid="structuralLimit"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Frame"
            input={
              <FastField name="frame">
                {({ field }: FastFieldProps) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={Boolean(touched.frame && errors.frame)}
                    inputProps={{
                      "data-testid": "frame",
                    }}
                    size="small"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Colour"
            input={
              <FastField name="colour">
                {({ field }: FastFieldProps) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={Boolean(touched.colour && errors.colour)}
                    inputProps={{
                      "data-testid": "colour",
                    }}
                    size="small"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="# of Washrooms"
            input={
              <FastField name="wash">
                {({ field }: FastFieldProps) => (
                  <PrimitiveSelect
                    size="small"
                    {...field}
                    value={values.wash}
                    fullWidth
                    error={Boolean(touched.wash && errors.wash)}
                    options={numberOfWashrooms}
                    data-testid="wash"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Partitions"
            input={
              <FastField name="partitions">
                {({ field }: FastFieldProps) => (
                  <PrimitiveSelect
                    size="small"
                    {...field}
                    value={values.partitions}
                    fullWidth
                    error={Boolean(touched.partitions && errors.partitions)}
                    options={partitions}
                    data-testid="partitions"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Complex"
            input={
              <FastField name="complex">
                {({ field }: FastFieldProps) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={Boolean(touched.complex && errors.complex)}
                    inputProps={{
                      "data-testid": "complex",
                    }}
                    size="small"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Axle Count"
            input={
              <FastField name="axleCount">
                {({ field }: FastFieldProps) => (
                  <TextField
                    {...field}
                    fullWidth
                    onChange={(e) =>
                      setFieldValue(
                        "axleCount",
                        e.currentTarget.value.replace(/\D/, "")
                      )
                    }
                    error={Boolean(touched.axleCount && errors.axleCount)}
                    inputProps={{
                      "data-testid": "axleCount",
                    }}
                    size="small"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Wind Rating"
            input={
              <FastField name="windRating">
                {({ field }: FastFieldProps) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={Boolean(touched.windRating && errors.windRating)}
                    inputProps={{
                      "data-testid": "windRating",
                    }}
                    size="small"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Roof Type"
            input={
              <FastField name="roofType">
                {({ field }: FastFieldProps) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={Boolean(touched.roofType && errors.roofType)}
                    inputProps={{
                      "data-testid": "roofType",
                    }}
                    size="small"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="In Service Date"
            input={
              <DateSelector
                value={values.inServiceDate ? dateFromMMDDYYYY(values.inServiceDate as string) : null}
                data-testid="inServiceDate"
                label="In Service Date"
                onChange={(date) =>
                  date && isValid(date) && 
                  setFieldValue("inServiceDate", toMMDDYYYY(new Date(date)))
                }
                textFieldProps={{
                  size: "small",
                  fullWidth: true,
                  error: Boolean(errors.inServiceDate),
                }}
                localeText={{
                  fieldMonthPlaceholder: () => 'MMM',
                }}
              />
            }
          />

          <AssetRow
            label="Orientation"
            input={
              <FastField name="orientation">
                {({ field }: FastFieldProps) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={Boolean(touched.orientation && errors.orientation)}
                    inputProps={{
                      "data-testid": "orientation",
                    }}
                    size="small"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Layout"
            input={
              <FastField name="layout">
                {({ field }: FastFieldProps) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={Boolean(touched.layout && errors.layout)}
                    size="small"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Key #"
            input={
              <FastField name="keyNumber">
                {({ field }: FastFieldProps) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={Boolean(touched.keyNumber && errors.keyNumber)}
                    size="small"
                  />
                )}
              </FastField>
            }
          />
          <AssetRow
            label="License Plate"
            input={
              <FastField name="licensePlate">
                {({ field }: FastFieldProps) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={Boolean(touched.licensePlate && errors.licensePlate)}
                    size="small"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Capital Cost"
            input={
              <Field name="capitalCost.amount">
                {({ field, form }: FastFieldProps) => (
                  <CurrencyTextField
                    {...field}
                    fullWidth
                    value={values.capitalCost}
                    onChange={(event) => {
                      form.setFieldValue(
                        field.name,
                        Number(event.target.value) || undefined
                      );
                    }}
                    onCurrencyChange={(event) => {
                      event.target.value &&
                        form.setFieldValue(
                          "capitalCost.currency",
                          event.target.value
                        );
                    }}
                    error={Boolean(touched.capitalCost && errors.capitalCost)}
                    inputProps={{
                      "data-testid": "capitalCost",
                    }}
                    size="small"
                  />
                )}
              </Field>
            }
          />

          <AssetRow
            label="Accumulated Impairment"
            input={
              <Field name="accumulatedImpairment.amount">
                {({ field, form }: FastFieldProps) => (
                  <CurrencyTextField
                    {...field}
                    fullWidth
                    value={values.accumulatedImpairment}
                    onChange={(event) => {
                      form.setFieldValue(
                        field.name,
                        Number(event.target.value) || undefined
                      );
                    }}
                    onCurrencyChange={(event) => {
                      event.target.value &&
                        form.setFieldValue(
                          "accumulatedImpairment.currency",
                          event.target.value
                        );
                    }}
                    error={Boolean(
                      touched.accumulatedImpairment &&
                        errors.accumulatedImpairment
                    )}
                    inputProps={{
                      "data-testid": "accumulatedImpairment",
                    }}
                    size="small"
                  />
                )}
              </Field>
            }
          />

          <AssetRow
            label="Useful Life"
            input={
              <FastField name="usefulLife">
                {({ field }: FastFieldProps) => (
                  <NumberTextField
                    {...field}
                    fullWidth
                    error={Boolean(touched.usefulLife && errors.usefulLife)}
                    inputProps={{
                      "data-testid": "usefulLife",
                    }}
                    size="small"
                  />
                )}
              </FastField>
            }
          />

          <AssetRow
            label="Residual Value"
            input={
              <FastField name="residualValue">
                {({ field }: FastFieldProps) => (
                  <PercentageTextField
                    {...field}
                    fullWidth
                    error={Boolean(
                      touched.residualValue && errors.residualValue
                    )}
                    inputProps={{
                      "data-testid": "residualValue",
                    }}
                    size="small"
                  />
                )}
              </FastField>
            }
          />
        </Grid>
      </Card>
    </Scrollbar>
  );
};

export default AssetForm;
