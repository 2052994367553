
import { Asset, blankAsset } from "store/models/Asset";
import { Currency } from "dinero.js";
import { createMongoId } from "utils/createMongoId";
import { AssetStatus } from "store/models/AssetStatus";

export function initAsset (assetProps?: Partial<Asset> & {currency: Currency}){
  return {
    ...blankAsset({ currency: "CAD" }),
    _id: createMongoId(),
    ...assetProps,
  };
}

export const reserveableAssetStatuses: AssetStatus[] = ["AVAILABLE"];
export const moveableAssetStatuses: AssetStatus[] = ["RESERVED", "ON LEASE", "SOLD PENDING DELIVERY"];

