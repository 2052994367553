import { TextFieldProps } from "@mui/material";
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers-pro';
import { PickersActionBar } from "@mui/x-date-pickers/PickersActionBar";
import { PickersActionBarAction } from "@mui/x-date-pickers/PickersActionBar/PickersActionBar";

interface PickerProps extends Omit<DatePickerProps<Date, boolean>, "renderInput"> {
  textFieldProps: TextFieldProps
  clearable?: boolean
}

const DateSelector: React.FC<PickerProps>= ({ textFieldProps, clearable = false, ...props}) => {

  const pickerActions: PickersActionBarAction[] = ["today", "cancel", ]
  clearable && pickerActions.push("clear")
  props.closeOnSelect === false && pickerActions.push("accept")

  return (
    (<DatePicker
      slots={{actionBar: PickersActionBar}}
      slotProps={{ actionBar: {actions: pickerActions}, textField: textFieldProps }}
      format="dd-MMM-yyyy"
      {...props}
    />)
  );
};

export default DateSelector;
