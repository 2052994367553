import { IInspection, IChecklistItem } from "store/models/Inspection";
import { IStandardWorkOrder } from "store/models/WorkOrder";
import { starfleetApi } from "store/services/api";
import { getResponseBody } from "store/services/utils";
import { setGlobalMessage } from "store/slices/systemSlice";

export const inspectionApi = starfleetApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getInspectionById: builder.query<IInspection, string>({
      query: (id) => `/inspection/${id}`,
      transformResponse: getResponseBody<IInspection>,
      providesTags: (result, error, id) => [
        { type: "Inspection", id: result?._id },
      ],
    }),
    updateInspection: builder.mutation<IInspection, IInspection>({
      query: (update) => ({
        url: `/inspection`,
        method: "PATCH",
        body: update,
      }),
      transformResponse: getResponseBody<IInspection>,
      invalidatesTags: (result, error, id) => [
        { type: "Inspection", id: result?._id },
      ],
      extraOptions: { maxRetries: 0 },
    }),
    updateCheckListItem: builder.mutation<
      IChecklistItem,
      { inspectionId: string; checklistItem: IChecklistItem }
    >({
      query(body) {
        return {
          url: `/inspection/checkListItem`,
          method: "PATCH",
          body: body,
        };
      },
      async onQueryStarted(
        { inspectionId, checklistItem },
        { dispatch, queryFulfilled }
      ) {
        const updateResult = dispatch(
          inspectionApi.util.updateQueryData(
            "getInspectionById",
            inspectionId,
            (draft) => {

              const inspectionChecklist = draft.inspectionChecklist.map(
                (item) =>
                  item._id === checklistItem._id ? checklistItem : item
              );
              Object.assign(draft, {inspectionChecklist});
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          updateResult.undo();
          dispatch(
            setGlobalMessage({
              messageText: "Failed to update checklist item",
              severity: "error",
              show: true,
            })
          );
        }
      },
      extraOptions: { maxRetries: 0 },
      transformResponse: getResponseBody<IChecklistItem>,
    }),
    generateInspectionWorkOrders: builder.mutation<
      { inspection: IInspection; workOrders: IStandardWorkOrder[] },
      { inspectionId: string }
    >({
      query(body) {
        return {
          url: `/inspection/workOrders`,
          method: "POST",
          body: body,
        };
      },
      extraOptions: { maxRetries: 0 },
      transformResponse: getResponseBody<{
        inspection: IInspection;
        workOrders: IStandardWorkOrder[];
      }>,
      invalidatesTags: (result, error, { inspectionId }) => [
        { type: "Inspection", id: inspectionId },
        { type: "WorkOrders", id: "LIST" },
        { type: "InspectionWorkOrders", id:inspectionId },
        "ContractWorkOrders",
      ],
    }),
  }),
});

export const {
  useGetInspectionByIdQuery,
  useUpdateCheckListItemMutation,
  useGenerateInspectionWorkOrdersMutation,
  useUpdateInspectionMutation,
} = inspectionApi;
