import React, { FC } from "react";
import {
  TextField,
  Autocomplete,
  AutocompleteProps,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectUnitsOfMeasure } from "store/slices/configSlice";

type Props = Omit<
  AutocompleteProps<string, boolean, boolean, boolean>,
  "options" | "renderInput"
> & {
  name?: string;
  error?: boolean;
  placeholder?: string;
};

const UOMAutocomplete: FC<Props> = ({ value, name, error, placeholder, ...rest }) => {
  const unitsOfMeasure = useSelector(selectUnitsOfMeasure);

  return (
    <Autocomplete
      {...rest}
      value={value}
      options={unitsOfMeasure.settings}
      size="small"
      clearOnBlur={false}
      renderInput={(params) => (
        <TextField
          name={name}
          {...params}
          error={error}
          margin="none"
          placeholder={placeholder}
          inputProps={{ ...params.inputProps, autocomplete: "new-password", form: { autocomplete: "off" } }}
        />
      )}
    />
  );
};

export default UOMAutocomplete;
