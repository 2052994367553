import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
} from "@mui/material";
import Movement, {
  MovementStatus,
  MovementType,
} from "store/models/Movement";
import { simpleGlobalMessage } from "store/slices/systemSlice";
import { isValid } from "date-fns/fp";
import HighlightOff from "@mui/icons-material/HighlightOff";
import DateSelector from "components/DateSelector";
import AsyncButton from "components/upload/AsyncButton";
import { dateFromMMDDYYYY, toMMDDYYYY } from "utils/util";
import { useAppDispatch } from 'store/store';
import { Contract } from "store/models/Contract";
import pluralize from "pluralize";
import ConfirmationModal from "components/ConfirmationModal";
import { differenceInDays} from "date-fns";
import TransportCompanyAutoComplete from "components/autocomplete/TransportCompanyAutoComplete";
import { TransportCompany } from "store/slices/transportCompanySlice";
import { useUpdateContractMovementsMutation } from "store/services/contract";
import { useGetAssetsByContractIdQuery } from "store/services/asset";
import { byIds } from "store/sliceUtils";

interface Props extends DialogProps {
  initialMovements: Movement[];
  currentContract: Contract;
  onClose: () => void;
}

const BulkMovementCreate: React.FC<Props> = ({ initialMovements, currentContract, onClose, ...rest }) => {
  const [newMovements, setNewMovements] = useState<Movement[]>(initialMovements);
  const [updateContractMovements, {isLoading: isAddingMovements}] = useUpdateContractMovementsMutation();
  const {data: assets = []} = useGetAssetsByContractIdQuery(currentContract._id || "", {skip: !currentContract._id});
  const assetsHash = byIds(assets);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if(!newMovements.length){
      onClose();
    }
  }, [newMovements.length])

  function handleRemove(assetId: string) {
    setNewMovements((currentMovements) => currentMovements.filter((move) => move.asset !== assetId));
  }

  const handleUnsavedMovementUpdate = (
    movementIndex: number,
    movementUpdate: Partial<Movement>
  ) => {
    const clonedMovements = [...newMovements];
    clonedMovements[movementIndex] = {
      ...clonedMovements[movementIndex],
      ...movementUpdate,
    } as Movement;
    setNewMovements(clonedMovements);
  };

  const changeAllMovementTypes = (newType: MovementType) => {
    return () => {
      setNewMovements(
        newMovements.map((move) => ({ ...move, type: newType }))
      );
    };
  };

  const createNewMovements = async () => {
    if (currentContract._id) {
      updateContractMovements({
        contractId: currentContract._id,
        movementUpdates: newMovements,
        action: "Add",
      })
        .unwrap()
        .then(({ contract, assets }) => {
          onComplete();
        })
        .catch((error) => {
          dispatch(
            simpleGlobalMessage(error.data.message || error.message, "error")
          );
        });
    }

    function onComplete() {
      dispatch(
        simpleGlobalMessage(
          `Successfully created ${newMovements.length} ${pluralize(
            "movement",
            newMovements.length
          )}`,
          "success"
        )
      );
      setNewMovements([]);
      onClose();
    }
  };

  const allMovementsHaveTheSameType = (type: string) =>
    newMovements.every((move) => move.type === type);

  const bulkTypeChanger = () => (
    <ButtonGroup disableElevation>
      {Object.values(MovementType).map((type) => {
        const color: "primary" | "inherit" = allMovementsHaveTheSameType(type)
          ? "primary"
          : "inherit";
        return (
          <Button
            key={type}
            color={color}
            onClick={changeAllMovementTypes(type)}
          >
            {type}
          </Button>
        );
      })}
    </ButtonGroup>
  );

  const setMovementDate = (date: unknown, index: number) => {
    const newDate = date as Date;
    if (newDate && isValid(new Date(newDate))) {
      handleUnsavedMovementUpdate(index, {
        dateOfMove: toMMDDYYYY(newDate),
      });
    }
  }

  function inMovementsScheduledBeforeEndDate() {
    if (currentContract._id) {
      const contractEndDate = currentContract.endDate;
      return newMovements.some((movement) => {
        if (movement.type !== "IN") return false;
        const assetDeliverable = currentContract.assetDeliverables.find(
          (deliverable) => deliverable.asset === movement.asset
        );
        if (assetDeliverable && assetDeliverable.customBillingEnd) {
          return (
            differenceInDays(
              new Date(movement.dateOfMove),
              dateFromMMDDYYYY(assetDeliverable.customBillingEnd)
            ) < 0
          );
        } else if (contractEndDate) {
          return (
            differenceInDays(
              new Date(movement.dateOfMove),
              dateFromMMDDYYYY(contractEndDate)
            ) < 0
          );
        } else {
          return false;
        }
      });
    }
  }

  return (
    <Dialog {...rest} onClose={onClose}>
      <DialogTitle>Create Movements</DialogTitle>
      <DialogContent
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(6, min-content)",
          gridTemplateRows: "auto auto",
          columnGap: 1.5,
          rowGap: 2,
          overflowY: "auto",
          maxHeight: "60vh",
          alignItems: "center",
          mt: 2,
        }}
      >
        <Box sx={{ gridColumn: "4 / 7" }}>
          {newMovements.length > 1 ? bulkTypeChanger() : null}
        </Box>
        {newMovements.map((move, index) => (
          <Fragment key={move._id}>
            <IconButton onClick={() => move.asset && handleRemove(move.asset)}>
              <HighlightOff />
            </IconButton>
            <Box sx={{ justifySelf: "flex-start", whiteSpace: "nowrap" }}>
              {move.asset ? assetsHash[move.asset]?.serialNumber : "No Asset"}
            </Box>
            <DateSelector
              value={dateFromMMDDYYYY(move.dateOfMove)}
              onChange={(date) => setMovementDate(date, index)}
              closeOnSelect
              textFieldProps={{
                size: "small",
                sx: {
                  minWidth: 160,
                },
              }}
            />
            <ButtonGroup>
              {Object.values(MovementType).map((type) => {
                const color: "primary" | "inherit" =
                  move.type === type ? "primary" : "inherit";

                return (
                  <Button
                    key={type}
                    color={color}
                    onClick={() => handleUnsavedMovementUpdate(index, { type })}
                  >
                    {type}
                  </Button>
                );
              })}
            </ButtonGroup>
            <TransportCompanyAutoComplete
              placeholder="Transport Company"
              sx={{ width: 240 }}
              size="small"
              fullWidth
              confirmBeforeCreate
              confirmBeforeCreateAction={onClose}
              value={move.transportCompanyName}
              onChange={(_, value) =>
                handleUnsavedMovementUpdate(index, {
                  transportCompanyName: (value as TransportCompany)?.name || "",
                  transportCompany: (value as TransportCompany)?._id || null
                })
              }
            />
            <ButtonGroup disableElevation>
              {Object.values(MovementStatus).map((status) => {
                const color: "primary" | "inherit" =
                  move.status === status ? "primary" : "inherit";

                return (
                  status !== MovementStatus.completed && (
                    <Button
                      key={status}
                      color={color}
                      onClick={() =>
                        handleUnsavedMovementUpdate(index, { status })
                      }
                    >
                      {status}
                    </Button>
                  )
                );
              })}
            </ButtonGroup>
          </Fragment>
        ))}
      </DialogContent>
      <DialogActions>
        {inMovementsScheduledBeforeEndDate() ? (
          <ConfirmationModal
            handleConfirmation={createNewMovements}
            message="Some In Movements are scheduled before the Contract End Date or Custom Billing End. Are you sure you wish to proceed?"
            marginRight={1.5}
          >
            <Button>Create</Button>
          </ConfirmationModal>
        ) : (
          <AsyncButton variant="contained" isLoading={isAddingMovements} onClick={createNewMovements}>Create</AsyncButton>
        )}
        <Button color="error" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default BulkMovementCreate;
