import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import Blade from "components/Blade";
import { selectBladeMode, setBladeMode, simpleGlobalMessage } from 'store/slices/systemSlice';
import { setCreatedClientId } from 'store/slices/clientSlice';
import ClientForm from 'features/clients/blades/bladeContent/ClientForm';
import { Client } from 'store/models/Client';
import { Form, Formik, FormikHelpers } from 'formik';
import Cancel from '@mui/icons-material/Cancel';
import Save from '@mui/icons-material/Save';
import { IconButton, CircularProgress, Button } from '@mui/material';
import { clientValidationSchema } from 'utils/formikAPI';
import { initClient } from 'store/api/client';
import { useAppDispatch } from 'store/store';
import { selectDefaultUserCountry } from 'store/slices/configSlice';
import { useCreateClientMutation } from 'store/services/client';
import { createMongoId } from 'utils/createMongoId';

const ClientCreateBlade: FC = () => {
  const dispatch = useAppDispatch();
  const bladeMode = useSelector(selectBladeMode);
  const defaultCountry = useSelector(selectDefaultUserCountry);
  const initialValues = initClient({country: defaultCountry});
  const [createClient] = useCreateClientMutation();


  const handleClientCreate = (
    client: Client,
    { setSubmitting, resetForm }: FormikHelpers<Client>
  ) => {
    createClient(client).unwrap().then(() => {
      dispatch(setCreatedClientId(client._id));
      resetForm({ values: { ...initialValues, _id: createMongoId() } });
      closeBlade();
      dispatch(simpleGlobalMessage("Client Created Successfully", "success"));
    }
    ).catch((error) => {
      dispatch(simpleGlobalMessage(error.data?.message || error.message || 'An error occurred', "error"));
      setSubmitting(false)});
  };
  
  const closeBlade = () => {
    dispatch(setBladeMode("none"))
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleClientCreate}
      validationSchema={clientValidationSchema}
    >
      {({ resetForm, isSubmitting }) => {
        const closeBlade = () => {
          dispatch(setBladeMode("none"));
          resetForm();
        };
        const bladeActions = [
          <IconButton
            color="primary"
            type="submit"
            disabled={isSubmitting}
            data-testid="submitClientForm"
            key="submitClientForm"
          >
            {isSubmitting ? <CircularProgress size={24} /> : <Save />}
          </IconButton>,
          <IconButton
            onClick={() => resetForm()}
            key="cancel"
          >
            <Cancel />
          </IconButton>,
        ];

        return (
          <Form>
            <Blade
              open={bladeMode === "clientCreate"}
              changeOpen={closeBlade}
              title="New Client"
              onTop={bladeMode === "clientCreate"}
              actions={bladeActions}
            >
              <ClientForm />
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            </Blade>
          </Form>
        );
      }}
    </Formik>
  );
};
export default ClientCreateBlade;
