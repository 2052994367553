import React, { useEffect, useState, SyntheticEvent, useRef } from "react";
import { Attachment } from "store/models/Attachment";
import { getDateFromObjectId, proxyUrl, toLocalDate } from "utils/util";
import { simpleGlobalMessage } from "store/slices/systemSlice";
import { useAppDispatch } from 'store/store';
import { format } from "date-fns";
import { Box, Button, Card, Typography } from "@mui/material";
import DeleteForever from "@mui/icons-material/DeleteForever";
import Download from "@mui/icons-material/Download";
import AttachFile from "@mui/icons-material/AttachFile";
import api from "store/api";

interface Props {
  documentId: string;
  documentType: "contract" | "asset" | "lead" | "workOrder";
  disabled: boolean;
  changeIsSubmitting?: (submitting: boolean) => void;
}

const AttachmentList: React.FC<Props> = ({
  documentId,
  disabled,
  changeIsSubmitting = () => {},
  documentType,
}) => {
  const {
    createOne: createAttachmentApi,
    getOne: getAttachmentApi,
    deleteOne: deleteAttachmentApi,
    getAllByContract: getAttachmentsApi,
  } = api.attachments;
  const [attachments, changeAttachments] = useState<Attachment[]>([]);
  const [currentlySubmitting, changeCurrentlySubmitting] = useState(false);
  const dispatch = useAppDispatch();

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleFileInputClick = () => {
    hiddenFileInput?.current?.click();
  };

  useEffect(() => {
    refreshAttachmentsList(documentId);
  }, [documentId]);

  const refreshAttachmentsList = (documentId: string) => {
    getAttachmentsApi(documentId, documentType, {
      onData: changeAttachments,
    });
  };

  const handleAttachmentDownload = async (
    e: SyntheticEvent,
    attachment: Attachment
  ) => {
    e.preventDefault();

    const downloadFileByUrl = async (targetUrl: string, fileName: string) => {
      const res = await fetch(proxyUrl + targetUrl);
      const blob = await res.blob();

      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.setAttribute("download", fileName);
      a.click();
    };

    getAttachmentApi(documentId, documentType, attachment._id,{
      onData: (res: any) => {
        downloadFileByUrl(res, attachment.fileName);
      },
    });
  };

  const handleAttachmentUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      dispatch(
        simpleGlobalMessage("You do not have permission to perform this action")
      );
      return;
    }

    const file = e.target.files!.length > 0 ? e.target.files![0] : null;
    file && rawHandleAttachmentUpload(file);
  };

  const rawHandleAttachmentUpload = (file: File) => {
    if (currentlySubmitting) {
      return;
    }

    const maxFileSize = 10 * 1024 * 1024; // 10 MB
    if (file.size > maxFileSize) {
      dispatch(
        simpleGlobalMessage(
          `The file was larger than ${maxFileSize / (1024 * 1024)} MB maximum!`
        )
      );
      return;
    }

    const form = new FormData();
    form.append("file", file);

    changeIsSubmitting(true)

    createAttachmentApi(documentId, documentType, form, {
      onComplete: () => {
        dispatch(simpleGlobalMessage("Successfully Attached File", "success"))
        refreshAttachmentsList(documentId);
        // reset the Html file input control
        const ctrl = document.getElementById(
          "attachment-file-input"
        ) as HTMLInputElement;
        if (ctrl) {
          ctrl.value = "";
        }
      },
      currentlySubmitting: (currentlySubmitting: boolean) => {
        changeCurrentlySubmitting(currentlySubmitting);
        changeIsSubmitting(currentlySubmitting);
      }
    });
  };

  const handleAttachmentDelete = (
    e: SyntheticEvent,
    attachment: Attachment
  ) => {
    e.preventDefault();

    if (disabled) {
      dispatch(
        simpleGlobalMessage("You do not have permission to perform this action")
      );
      return;
    }

    const { _id: attachmentId, fileName } = attachment;

    window.confirm(`Are you sure you wish to delete '${fileName}' file?`) &&
      deleteAttachmentApi(documentId, documentType, attachmentId, {
        onData: () => {
          refreshAttachmentsList(documentId);
        },
        onError: (message: string) => dispatch(simpleGlobalMessage(message)),
      });
  };

  function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(decimals))} ${sizes[i]}`;
  }

  return (
    <Box>
      <Button
        fullWidth
        variant="outlined"
        startIcon={<AttachFile />}
        onClick={handleFileInputClick}
        sx={{ mb: 3, mt: 1 }}
        disabled={disabled}
      >
        Attach file
        <input
          style={{ display: "none" }}
          id="attachment-file-input"
          type="file"
          ref={hiddenFileInput}
          name="attachmentUpload"
          onChange={handleAttachmentUpload}
        />
      </Button>
      <Box>
        {attachments.map((attachment) => (
          <Card
            key={attachment._id}
            sx={{
              display: "grid",
              rowGap: 2,
              columnGap: 2,
              gridTemplateColumns: "auto 1fr 1fr",
              gridTemplateAreas: `fileName download remove
                                  uploadDate size size`,
              alignItems: "center",
              p: 2.5,
              mb: 2,
            }}
          >
            <Typography
              variant="body2"
              sx={{
                maxWidth: "100%",
                textOverFlow: "ellipsis",
                overflowX: "hidden",
              }}
            >
              {attachment.fileName}
            </Typography>
            <Box
              onClick={(e) => handleAttachmentDownload(e, attachment)}
            >
              <Button startIcon={<Download />}>Download</Button>
            </Box>
            <Box
              onClick={(e) => handleAttachmentDelete(e, attachment)}
            >
              <Button startIcon={<DeleteForever />}>Remove</Button>
            </Box>
            <Typography
              variant="subtitle2"
            >
              Uploaded:{" "}
              {format(
                toLocalDate(new Date(getDateFromObjectId(attachment._id))),
                "MM-dd-yyyy"
              )}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                pl: 1,
              }}
            >
              Size: {formatBytes(attachment.fileSize)}
            </Typography>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default AttachmentList;
