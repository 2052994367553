import React from "react";
import { FastField, FastFieldProps } from "formik";
import { TextField, TextFieldProps } from "@mui/material";

const FastTextInput: React.FC<{ textFieldProps?: TextFieldProps,  label?: string; name: string, allowHelperText?:boolean; leaveSpaceForHelperText?: boolean }> = ({
  label = "",
  name,
  allowHelperText,
  leaveSpaceForHelperText,
  textFieldProps,
  ...props
}) => {

  return (
    <FastField {...props} name={name}>
      {({ field, meta }: FastFieldProps) => {return (
        <TextField
          {...field}
          fullWidth
          label={label}
          error={meta.touched && !!meta.error}
          helperText={allowHelperText ? meta.error || leaveSpaceForHelperText ? " " : "" : ""}
          variant="outlined"
          size="small"
          {...textFieldProps}
        />
      )}}
    </FastField>
  );
};

export default FastTextInput;
