import { fCurrency, fCurrencyFloat } from "utils/formatNumber";
import { Asset, calcAccumulatedAmortization, calcMonthlyDepreciation, calcNetBookValue } from "store/models/Asset";
import { GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowId } from "@mui/x-data-grid-pro";
import { inOperator, notInOperator } from "components/data-grid/column-filters";
import { Dispatch, SetStateAction, useMemo } from "react";
import { Tooltip } from "@mui/material";
import PushPin from "@mui/icons-material/PushPin";
import Label from "components/label";
import { format } from "date-fns";
import { AssetWithFinancialData } from "store/slices/assetSlice";
import { DDMMMYYYY } from "utils/util";
import { ICurrency } from "types/Currency";


export function statusToColor(status: AssetWithFinancialData["status"]) {
  switch (status) {
    case "AVAILABLE":
      return "success";
    case "RESERVED":
      return "warning";
    case "SOLD PENDING DELIVERY":
      return "warning";
    case "NOT RENTABLE":
      return "error";
    case "MAINTENANCE NEEDED":
      return "error";
    case "IN MAINTENANCE":
      return "error";
    case "ON LEASE":
      return "info";
    default:
      return "default";
  }
}

type AssetColumnProps = {
  pinnedRowsIds: {
    top: GridRowId[];
    bottom: GridRowId[];
  };
  setPinnedRowsIds: Dispatch<SetStateAction<{
    top: GridRowId[];
    bottom: GridRowId[];
  }>>;
};

const useStyledAssetsTableColumns = ({pinnedRowsIds, setPinnedRowsIds }: AssetColumnProps) => {


  const assetColumns: GridColDef<AssetWithFinancialData>[] = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Serial #",
        width: 120,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "branch",
        headerName: "Branch",
        width: 80,
        filterOperators: [
          inOperator("in-branch"),
          notInOperator("not-in-branch"),
        ],
        align: "center",
        headerAlign: "center",
      },
      {
        field: "yard",
        headerName: "Yard",
        width: 120,
        filterOperators: [inOperator("in-yard"), notInOperator("not-in-yard")],
        align: "center",
        headerAlign: "center",
      },
      {
        field: "yardPosition",
        headerName: "Yard Position",
        width: 120,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "assetNumber",
        headerName: "Asset #",
        width: 120,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "category",
        headerName: "Category",
        width: 160,
        filterOperators: [
          inOperator("in-category"),
          notInOperator("not-in-category"),
        ],
        align: "center",
        headerAlign: "center",
      },
      {
        field: "sizeCode",
        headerName: "Size",
        width: 80,
        filterOperators: [
          inOperator("in-sizeCode"),
          notInOperator("not-in-sizeCode"),
        ],
        align: "center",
        headerAlign: "center",
      },
      {
        field: "status",
        headerName: "Status",
        width: 190,
        filterOperators: [
          inOperator("in-status"),
          notInOperator("not-in-status"),
        ],
        align: "center",
        headerAlign: "center",
        renderCell: (params: GridRenderCellParams<Asset>) => (
          <Label variant="soft" color={statusToColor(params.value)}>
            {params.value}
          </Label>
        ),
      },
      {
        field: "hrs",
        headerName: "Hrs",
        type: "number",
        width: 60,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "condition",
        headerName: "Condition",
        type: "number",
        width: 80,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "inServiceDate",
        headerName: "In Service Date",
        type: "date",
        valueFormatter: (value: DDMMMYYYY) =>
          value
            ? format(new Date(value), "dd-MMM-yyyy")
            : "Not Set",
        width: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "colour",
        headerName: "Colour",
        width: 120,
        filterOperators: [
          inOperator("in-colour"),
          notInOperator("not-in-colour"),
        ],
        align: "center",
        headerAlign: "center",
      },
      {
        field: "frame",
        headerName: "Frame",
        width: 120,
        filterOperators: [
          inOperator("in-frame"),
          notInOperator("not-in-frame"),
        ],
        align: "center",
        headerAlign: "center",
      },
      {
        field: "partitions",
        headerName: "Partitions",
        type: "number",
        width: 80,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "notes",
        headerName: "Notes",
        width: 300,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "complex",
        headerName: "Complex",
        width: 120,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "wash",
        headerName: "Wash",
        width: 60,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "subCategory",
        headerName: "Sub Category",
        width: 180,
        filterOperators: [
          inOperator("in-subCategory"),
          notInOperator("not-in-subCategory"),
        ],
        align: "center",
        headerAlign: "center",
      },
      {
        field: "heatingType",
        headerName: "Heating Type",
        width: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "axleCount",
        headerName: "Axle Count",
        width: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "windRating",
        headerName: "Wind Rating",
        width: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "roofType",
        headerName: "Roof Type",
        width: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "floorStyle",
        headerName: "Floor Style",
        width: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "acType",
        headerName: "A/C Type",
        width: 100,
      },
      {
        field: "branding",
        headerName: "Branding",
        width: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "buildingCode",
        headerName: "Building Code",
        width: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "cladding",
        headerName: "Cladding",
        width: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "electrical",
        headerName: "Electrical",
        width: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "fuelType",
        headerName: "Fuel Type",
        width: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "manufacturer",
        headerName: "Manufacturer",
        width: 120,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "orientation",
        headerName: "Orientation",
        width: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "layout",
        headerName: "Layout",
        width: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "keyNumber",
        headerName: "Key #",
        width: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "licensePlate",
        headerName: "License Plate",
        width: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "residualValue",
        headerName: "ResidualValue",
        type: "number",
        valueFormatter: (value: number) => `${value}%`,
        align: "center",
        headerAlign: "center",
        width: 100,
      },
      {
        field: "structuralLimit",
        headerName: "Structural Limit",
        width: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "usefulLife",
        headerName: "Useful Life",
        type: "number",
        width: 100,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "yearOfManufacture",
        headerName: "Year of Manufacture",
        width: 160,
        align: "center",
        headerAlign: "center",
        type: "number",
      },
      {
        field: "inProduction",
        headerName: "In Production",
        width: 100,
        filterOperators: [
          inOperator("in-inProduction"),
          notInOperator("not-in-inProduction"),
        ],
        valueGetter: (value: boolean) => (value ? "Yes" : "No"),
        align: "center",
        headerAlign: "center",
      },
      {
        field: "currentRentalRate",
        headerName: "Current Rental Rate",
        type: "number",
        width: 160,
        align: "right",
        headerAlign: "center",
        valueGetter: (_, row) => row.currentRentalRate?.amount,
        renderCell: (params: GridRenderCellParams<AssetWithFinancialData>) => {
          return params.row.currentRentalRate ? (
            `${fCurrency(params.row.currentRentalRate.amount)} ${params.row.currentRentalRate.currency}`
          ) : (
            "-"
          );
        }
      },
      {
        field: "payBack",
        headerName: "Payback (months)",
        type: "number",
        width: 160,
        align: "center",
        headerAlign: "center",
        valueFormatter: (value: number) =>
          value ? value.toFixed(1) : "-",
      },
      {
        field: "netBookValue",
        headerName: "NBV",
        type: "number",
        width: 100,
        valueGetter: (value: number) => fCurrencyFloat(value),
        align: "center",
        headerAlign: "center",
      },
      {
        field: "nbv-starfleet",
        headerName: "NBV (Starfleet)",
        type: "number",
        width: 100,
        valueGetter: (_, row) =>
          calcNetBookValue(row),
        valueFormatter: (value: number) =>
          value ? fCurrency(value) : "Not Set",
        align: "right",
        headerAlign: "center",
      },
      {
        field: "capitalCost",
        headerName: "Capital Cost",
        type: "number",
        width: 100,
        valueGetter: (value: ICurrency) => value?.amount,
        valueFormatter: (value: number) =>
          value ? fCurrency(value) : "Not Set",
        headerAlign: "center",
      },
      {
        field: "accumulatedAmortization",
        headerName: "Acc. Amortization",
        type: "number",
        width: 140,
        valueGetter: (_, row) => calcAccumulatedAmortization(row),
        valueFormatter: (value: number) =>
          value ? fCurrency(value) : "",
        align: "right",
        headerAlign: "center",
      },
      {
        field: "accumulatedImpairment",
        headerName: "Acc. Impairment",
        type: "number",
        width: 140,
        valueGetter: (_, row) => row.accumulatedImpairment.amount || 0,
        valueFormatter: (value: number) =>
          value ? fCurrency(value) : "0",
        align: "right",
        headerAlign: "center",
      },
      {
        field: "depreciationRate",
        headerName: "Depreciation Rate",
        width: 140,
        type: "number",
        valueFormatter: (value: number) =>
          value ? `${value.toFixed(1)}%` : "Not Set",
        align: "center",
        headerAlign: "center",
      },
      {
        field: "starfleetHours",
        headerName: "Starfleet Hrs",
        valueFormatter: (value: number) => 
          value || "",
        type: "number",
        width: 140,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "__monthlyDep",
        headerName: "Monthly Dep.",
        type: "number",
        width: 100,
        valueGetter: (_, row) => calcMonthlyDepreciation(row),
        valueFormatter: (value: number) =>
          value ? fCurrency(value) : "",
        align: "right",
        headerAlign: "center",
      },
      {
        field: "actions",
        type: "actions",
        width: 40,
        getActions: (params) => {
          const isPinnedTop = pinnedRowsIds.top.includes(params.row._id);
          const handlePinClick = () => {
            if (isPinnedTop) {
              setPinnedRowsIds((prevPinnedRowsIds) => ({
                ...prevPinnedRowsIds,
                top: prevPinnedRowsIds.top.filter(
                  (rowId) => rowId !== params.row._id
                ),
              }));
            } else {
              setPinnedRowsIds((prevPinnedRowsIds) => ({
                ...prevPinnedRowsIds,
                top: [...prevPinnedRowsIds.top, params.row._id],
              }));
            }
          };
          if (isPinnedTop) {
            return [
              <GridActionsCellItem
                label="Unpin"
                icon={
                  <Tooltip title="Unpin">
                    <PushPin color="primary" />
                  </Tooltip>
                }
                onClick={handlePinClick}
              />,
            ];
          }
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Pin to top">
                  <PushPin />
                </Tooltip>
              }
              label="Pin to top"
              onClick={handlePinClick}
            />,
          ];
        },
      },
    ],
    [pinnedRowsIds]
  );

  return assetColumns

};
export default useStyledAssetsTableColumns;


