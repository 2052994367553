import { addHours } from "date-fns";
import { Currency } from "dinero.js";
import { getQueryStringFromObject } from "store/api/util";
import IAccessory from "store/models/Accessory";
import Address from "store/models/Address";
import { Asset } from "store/models/Asset";
import { AssetDeliverable } from "store/models/AssetDeliverable";
import { Client } from "store/models/Client";
import { Contract, ContractStatus } from "store/models/Contract";
import { IContractHistory } from "store/models/ContractHistory";
import Movement from "store/models/Movement";
import { starfleetApi } from "store/services/api";
import { getResponseBody } from "store/services/utils";
import { ICurrency, initCurrency } from "types/Currency";
import { createMongoId } from "utils/createMongoId";
import { toMMDDYYYY } from "utils/util";


export interface ContractFilters {
  projectNumber?: string;
  statuses?: ContractStatus[];
  client?: Pick<Client, "_id" | "companyName"> | null;
}

export interface ContractTableData {
  _id: string;
  projectNumber: string;
  status: ContractStatus;
  billTo: Address;
  shipTo: Address;
  deliveryInstructions: string;
  assets: {_id: string, serialNumber: string, assetNumber: string}[];
  createdBy: string;
  companyName: string;
  contractType: string,
  outDate: string,
  endDate: string,
  startDate: string,
  reservationExpiry: string,
  contractSigned: boolean,
  branches: string[],
  additionalComments: string,
  evergreen: boolean,
  actionRequired: {
    actionName: string;
    urgency: "none" | "yellow" | "red";
  },
}


export const contractApi = starfleetApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getContractById: builder.query<Contract, string>({
      query: (contractId) => `contract/single?contractId=${contractId}`,
      transformResponse: getResponseBody<Contract>,
      providesTags: (result, error, id) => [
        { type: "Contracts", id: result?._id },
      ],
    }),
    getContractsByClientId: builder.query<Contract[], string>({
      query: (clientId) => `client/contracts?clientId=${clientId}`,
      transformResponse: getResponseBody<Contract[]>,
      providesTags: (result, error, id) => [{type: "ClientContracts", id}],
    }),
    getContractHistory: builder.query<IContractHistory, string>({
      query: (contractId) => `contract/history?contractId=${contractId}`,
      transformResponse: getResponseBody<IContractHistory>,
      providesTags: (result, error, id) => [{ type: "ContractHistory", id }],
    }),
    getContracts: builder.query<ContractTableData[], {}>({
      query: (query) =>
        `/contract/contractCardQuery?${getQueryStringFromObject(query)}`,
      transformResponse: getResponseBody<ContractTableData[]>,
      providesTags: (result, error, id) => [{ type: "Contracts", id: "LIST" }],
    }),
    createContract: builder.mutation<
      { assets: Asset[]; contract: Contract },
      Contract
    >({
      query: (body) => ({
        url: `contract/`,
        method: "POST",
        body,
      }),
      extraOptions: { maxRetries: 0 },
      transformResponse: getResponseBody<{
        assets: Asset[];
        contract: Contract;
      }>,
      invalidatesTags: (result, err, {assetDeliverables}) => [
        { type: "Contracts", id: "LIST" },
        { type: "Assets", id: "LIST" },
        ...assetDeliverables.map(({asset}) => ({type: "Assets" as const, id: asset})),
        "ActionsRequired",
      ],
    }),
    updateContract: builder.mutation<
      { assets: Asset[]; contract: Contract },
      Partial<Contract> & { _id: Contract["_id"] }
    >({
      query: (body) => ({
        url: `contract/`,
        method: "PATCH",
        body,
      }),
      extraOptions: { maxRetries: 0 },
      transformResponse: getResponseBody<{
        assets: Asset[];
        contract: Contract;
      }>,
      invalidatesTags: (result, err, { _id }) => [
        { type: "Contracts", id: "LIST" },
        { type: "Contracts", id: _id },
        "ActionsRequired",
      ],
    }),
    swapAsset: builder.mutation<
      { contract: Contract; newAsset: Asset; oldAsset: Asset },
      { contractId: string; oldAssetId: string; newAssetId: string }
    >({
      query: (body) => ({
        url: `contract/swapAsset`,
        method: "PATCH",
        body,
      }),
      extraOptions: { maxRetries: 0 },
      transformResponse: getResponseBody<{
        contract: Contract;
        newAsset: Asset;
        oldAsset: Asset;
      }>,
      invalidatesTags: (result, err, { contractId, oldAssetId, newAssetId }) => [
        { type: "Contracts", id: contractId },
        { type: "Assets", id: contractId },
        { type: "Assets", id: oldAssetId },
        { type: "Assets", id: newAssetId },
        { type: 'Assets', id: "LIST" },
      ],
    }),
    removeAsset: builder.mutation<
      { contract: Contract; removedAsset: Asset },
      { contractId: string; assetToRemoveId: string }
    >({
      query: (body) => ({
        url: `contract/removeAsset`,
        method: "PATCH",
        body,
      }),
      extraOptions: { maxRetries: 0 },
      transformResponse: getResponseBody<{
        contract: Contract;
        removedAsset: Asset;
      }>,
      invalidatesTags: (result, err, { contractId, assetToRemoveId }) => [
        { type: "Contracts", id: contractId },
        { type: "Assets", id: contractId },
        { type: "Assets", id: "LIST" },
        { type: "Assets", id: assetToRemoveId },
        "ActionsRequired",
      ],
    }),
    removeAccessory: builder.mutation<
      { contract: Contract },
      { contractId: string; accessoryToRemoveId: string }
    >({
      query: (body) => ({
        url: `contract/removeAccessory`,
        method: "PATCH",
        body,
      }),
      extraOptions: { maxRetries: 0 },
      transformResponse: getResponseBody<{ contract: Contract }>,
      invalidatesTags: (result, err, { contractId }) => [
        { type: "Contracts", id: contractId },
      ],
    }),
    addAsset: builder.mutation<
      { contract: Contract; addedAssets: Asset[] },
      { contractId: string; assetsToAdd: string[] }
    >({
      query: (body) => ({
        url: `contract/addAsset`,
        method: "PATCH",
        body,
      }),
      extraOptions: { maxRetries: 0 },
      transformResponse: getResponseBody<{
        contract: Contract;
        addedAssets: Asset[];
      }>,
      invalidatesTags: (result, err, { contractId, assetsToAdd }) => [
        { type: "Contracts", id: contractId },
        { type: "Assets", id: contractId },
        { type: "Assets", id: "LIST" },
        ...assetsToAdd.map((asset) => ({ type: "Assets" as const, id: asset })),
        "ActionsRequired",
      ],
    }),
    addAccessory: builder.mutation<
      { contract: Contract },
      { contractId: string; accessory: IAccessory }
    >({
      query: (body) => ({
        url: `contract/addAccessory`,
        method: "PATCH",
        body,
      }),
      extraOptions: { maxRetries: 0 },
      transformResponse: getResponseBody<{ contract: Contract }>,
      invalidatesTags: (result, err, { contractId }) => [
        { type: "Contracts", id: contractId },
      ],
    }),
    updateAssetDeliverables: builder.mutation<
      Contract,
      { contractId: string; assetDeliverables: AssetDeliverable[] }
    >({
      query: (body) => ({
        url: `contract/assetDeliverables`,
        method: "PATCH",
        body,
      }),
      extraOptions: { maxRetries: 0 },
      transformResponse: getResponseBody<Contract>,
      invalidatesTags: (result, err, { contractId }) => [
        { type: "Contracts", id: contractId },
        "ActionsRequired",
      ],
    }),
    updateAccessories: builder.mutation<
      Contract,
      { contractId: string; accessories: IAccessory[] }
    >({
      query: (body) => ({
        url: `contract/updateAccessories`,
        method: "PATCH",
        body,
      }),
      extraOptions: { maxRetries: 0 },
      transformResponse: getResponseBody<Contract>,
      invalidatesTags: (result, err, { contractId }) => [
        { type: "Contracts", id: contractId },
      ],
    }),
    revertContractStatus: builder.mutation<
      { contract: Contract; assets: Asset[] },
      { contractId: string; currentStatus: Contract["status"] }
    >({
      query: (body) => ({
        url: `contract/revertContractStatus`,
        method: "PATCH",
        body,
      }),
      extraOptions: { maxRetries: 0 },
      transformResponse: getResponseBody<{
        contract: Contract;
        assets: Asset[];
      }>,
      invalidatesTags: (result, err, { contractId }) => [
        { type: "Contracts", id: contractId },
        { type: "Contracts", id: "LIST" },
        { type: "Assets", id: contractId },
        { type: 'Assets', id: "LIST" },
        ...(result?.assets || []).map(({_id}) => ({type: "Assets" as const, id: _id})),

        "ActionsRequired",
      ],
    }),
    advanceContractStatus: builder.mutation<
      { contract: Contract; assets: Asset[] },
      { contractId: string; currentStatus: Contract["status"] }
    >({
      query: (body) => ({
        url: `contract/advanceContractStatus`,
        method: "PATCH",
        body,
      }),
      extraOptions: { maxRetries: 0 },
      transformResponse: getResponseBody<{
        contract: Contract;
        assets: Asset[];
      }>,
      invalidatesTags: (result, err, { contractId }) => [
        { type: "Contracts", id: contractId },
        { type: "Contracts", id: "LIST" },
        { type: "Assets", id: contractId },
        ...(result?.assets || []).map(({_id}) => ({type: "Assets" as const, id: _id})),
        "ActionsRequired",
      ],
    }),
    cancelContract: builder.mutation<
      { contract: Contract; assets: Asset[] },
      { contractId: string; action: "CANCEL" | "DENY" }
    >({
      query: (body) => ({
        url: `contract/cancel`,
        method: "PATCH",
        body,
      }),
      extraOptions: { maxRetries: 0 },
      transformResponse: getResponseBody<{
        contract: Contract;
        assets: Asset[];
      }>,
      invalidatesTags: (result, err, { contractId }) => [
        { type: "Contracts", id: contractId },
        { type: "Contracts", id: "LIST" },
        { type: "Assets", id: contractId },
        { type: 'Assets', id: "LIST" },
        ...(result?.assets || []).map(({_id}) => ({type: "Assets" as const, id: _id})),
        "ActionsRequired",
      ],
    }),
    updateContractMovements: builder.mutation<
      { contract: Contract; assets: Asset[] },
      {
        contractId: string;
        movementUpdates: Movement[];
        action: "Add" | "Remove" | "Update";
      }
    >({
      query: (body) => ({
        url: `contract/movements`,
        method: "PATCH",
        body,
      }),
      extraOptions: { maxRetries: 0 },
      transformResponse: getResponseBody<{
        contract: Contract;
        assets: Asset[];
      }>,
      invalidatesTags: (result, err, { contractId, movementUpdates }) => [
        { type: "Contracts", id: contractId },
        { type: "Movements", id: "LIST" },
        { type: "WorkOrders", id: "LIST" },
        { type: "ContractWorkOrders", id: contractId },
        ...movementUpdates.map(({ _id }) => ({
          type: "Movements" as const,
          id: _id,
        })),
      ],
    }),
    updateMovementStatus: builder.mutation<
      { contract: Contract; assets: Asset[] },
      { contractId: string; movementId: string; newStatus: string }
    >({
      query: (body) => ({
        url: `contract/movements/status`,
        method: "PATCH",
        body,
      }),
      extraOptions: { maxRetries: 0 },
      transformResponse: getResponseBody<{
        contract: Contract;
        assets: Asset[];
      }>,
      invalidatesTags: (result, err, { contractId, movementId }) => [
        { type: "Contracts", id: contractId },
        { type: "Contracts", id: "LIST" },
        { type: "Assets", id: contractId },
        { type: 'Assets', id: "LIST" },
        ...(result?.assets || []).map(({_id}) => ({type: "Assets" as const, id: _id})),
        { type: "Movements", id: "LIST" },
        { type: "Movements", id: movementId },
        "ActionsRequired",
      ],
    }),
    getContractCSVTemplate: builder.query<BlobPart, void>({
      query: () => `contract/csv`,
      transformResponse: getResponseBody<string>,
    }),
    getContractAutocompleteOptions: builder.query<
      Contract[],
      Partial<{
        limit: number;
        searchValue: string;
        onlyContractsWithProjectNumber?: boolean;
      }>
    >({
      query: (params) => {
        const query = getQueryStringFromObject(params);
        return `/contract/contractAutocompleteOptions?${query}`;
      },
      transformResponse: getResponseBody,
      providesTags: (result, error, id) =>
        result
          ? [
              ...result.map(({ _id }) => ({
                type: "Contracts" as const,
                id: _id,
              })),
              { type: "Contracts", id: "AUTOCOMPLETE" },
            ]
          : [{ type: "Contracts", id: "AUTOCOMPLETE" }],
    }),
    getTotalNumberOfActionsRequired: builder.query<number, void>({
      query: () => `/contract/totalNumberOfActionsRequired`,
      transformResponse: getResponseBody,
      providesTags: ["ActionsRequired"],
    }),
  }),
});

export const {
  useGetContractByIdQuery,
  useGetContractHistoryQuery,
  useGetContractsQuery,
  useAddAccessoryMutation,
  useAddAssetMutation,
  useAdvanceContractStatusMutation,
  useCancelContractMutation,
  useCreateContractMutation,
  useRemoveAccessoryMutation,
  useRemoveAssetMutation,
  useRevertContractStatusMutation,
  useSwapAssetMutation,
  useUpdateAccessoriesMutation,
  useUpdateAssetDeliverablesMutation,
  useUpdateContractMovementsMutation,
  useUpdateContractMutation,
  useUpdateMovementStatusMutation,
  useLazyGetContractCSVTemplateQuery,
  useGetContractAutocompleteOptionsQuery,
  useGetTotalNumberOfActionsRequiredQuery,
  useGetContractsByClientIdQuery
} = contractApi;




export const placeholderAddress = {prefix: "", latLng: {lat: "", lng: ""}};

export const initContract = (
  contract?: Partial<
    Contract & {
      createdBy: string;
      securityDeposit: { received: boolean; amount: ICurrency };
    }
  >,
  currency?: Currency,
): Contract => ({
  _id: createMongoId(),
  branches: [],
  customerName: "",

  additionalComments: "",
  deliveryInstructions: "",
  contractType: "Rental",
  contractSigned: false,
  startDate: toMMDDYYYY(new Date()),
  endDate: toMMDDYYYY(new Date()),
  outDate: toMMDDYYYY(new Date()),
  reservationExpiry: toMMDDYYYY(addHours(new Date(), 72)),
  evergreen: false,
  assetDeliverables: [],
  status: "AWAITING APPROVAL",
  projectNumber: "",
  createdBy: "",
  client: "",
  movements: [],
  billTo: placeholderAddress,
  shipTo: placeholderAddress,
  accessories: [],
  securityDeposit: {
    received: false,
    amount: initCurrency({amount: 0, currency: currency || "CAD"})
  },
  clientPoNumber: "",
  quoteNumber: "",
  ppsaRegistration: "",
  clientContact: null,
  siteContact: null,
  ...contract,
});

export const initAccessory = (
  accessory: Partial<IAccessory> & { name: string; rate: IAccessory["rate"]}
): IAccessory => ({
  _id: createMongoId(),
  asset: null,
  unitOfMeasure: "EA",
  quantity: 1,
  ...accessory,
  rate: accessory.rate,
  name: accessory.name,
  customBillingStart: null,
  customBillingEnd: null,
});

export const contractAssetMoveableStatuses: ContractStatus[] = ["AWAITING CONFIRMATION", "PENDING DELIVERY", "ACTIVE"];


