import { PopulatedWorkOrder, WorkOrder, WorkOrderStatus } from "store/models/WorkOrder";
import { starfleetApi } from "store/services/api";
import { getResponseBody } from "store/services/utils";
import { setGlobalMessage } from "store/slices/systemSlice";
import { getQueryStringFromObject } from "store/api/util";
import { oneHour } from "utils/timeValues";
import store, { STARFLEET_STORE } from "store/store";
import { AssetDeliverable } from "store/models/AssetDeliverable";
import { IInspection } from "store/models/Inspection";


export interface WorkOrderQuery {
  workOrderNumber?: number;
  assetNumber?: string;
  serialNumber?: string;
  statuses?: WorkOrderStatus[];
  branch?: string;
  priority?: string;
  category?: string;
  subCategory?: string;
  pendingMovement?: boolean;
  workOrderType?: string;
  startDate?: string;
  endDate?: string;
  assignedTo?: string;
  createdBy?: string;
  client?: string;
  projectNumber?: string;
}

export interface AssetWorkOrder extends Omit<WorkOrder, 'inspection'> {
  inspection: IInspection
}

export interface WebWOQueryResponse extends Omit<WorkOrder, "asset" | "contract" | "assignedTo" | "createdBy" | "parent" | "photos" | "completedBy"> {
  asset: {
    _id: string;
    serialNumber: string;
    assetNumber: string
  };
  contract?: {
    _id: string;
    customerName: string;
    projectNumber: string;
  };
  assignedTo?: {
    _id: string;
    firstName: string;
    lastName: string;
  };
  createdBy: {
    _id: string;
    firstName: string;
    lastName: string;
  };
  completedBy: {
    _id: string;
    firstName: string;
    lastName: string;
  } | null;
  parent?: {
    workOrder?: {_id: string, number: number},
    inspection?: string,
    inspectionChecklistItem?: string
  }
}

export type WorkOrdersByContractId = Omit<WorkOrder, "contract"> & {
  contract: {
    _id: string;
    customerName: string;
    projectNumber: string;
    assetDeliverables: Omit<AssetDeliverable, "asset"> &
      {
        asset: {
          _id: string;
          assetNumber: string;
          serialNumber: string;
          sizeCode: string;
        };
      }[];
  };
};

export function getWorkOrderFromQueryResponse(response: WebWOQueryResponse): WorkOrder {
  return {
    ...response,
    asset: response.asset._id,
    contract: response.contract?._id || null,
    assignedTo: response.assignedTo ? response.assignedTo._id : undefined,
    createdBy: response.createdBy._id,
    completedBy: response.completedBy?.firstName ? response.completedBy._id : null,
    parent: response.parent?.workOrder?._id ?  {inspection: response.parent.inspection, workOrder: response.parent.workOrder, inspectionChecklistItem: response.parent.inspectionChecklistItem} : undefined,
  } as WorkOrder
}

export const workOrderApi = starfleetApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getWorkOrderById: builder.query<WebWOQueryResponse, string>({
      query: (id) => `/workOrder/${id}`,
      transformResponse: getResponseBody,
      providesTags: (result, error, id) => [
        { type: "WorkOrder", id: result?._id },
      ],
    }),
    createWorkOrder: builder.mutation<
      WorkOrder,
      {
        workOrder: WorkOrder;
        type: "inspection" | "workOrder";
        inspectionType?: string;
      }
    >({
      query({ workOrder, type, inspectionType }) {
        return {
          url: `/${type}`,
          method: "POST",
          body:
            type === "inspection" ? { workOrder, inspectionType } : workOrder,
        };
      },
      extraOptions: { maxRetries: 0 },
      transformResponse: getResponseBody,
      invalidatesTags: (result, err, args) => [
        { type: "WorkOrders", id: "LIST" },
        { type: "AssetWorkOrders", id: result?.asset },
        { type: "ContractWorkOrders", id: result?.contract || '' },
        "OpenHoursByAssetId"
      ],
    }),
    updateWorkOrder: builder.mutation<WorkOrder, WebWOQueryResponse>({
      query(body) {
        return {
          url: `/workOrder`,
          method: "PATCH",
          body: getWorkOrderFromQueryResponse(body),
        };
      },
      onQueryStarted(workOrder, { dispatch, queryFulfilled }) {
        const state: STARFLEET_STORE = store.getState();
        const updateSingleResult = dispatch(
          workOrderApi.util.updateQueryData(
            "getWorkOrderById",
            workOrder._id,
            (draft) => Object.assign(draft, workOrder)
          )
        );
        const updateListResult = dispatch(
          workOrderApi.util.updateQueryData(
            "getWorkOrdersBy",
            state?.maintenance?.workOrderQueryArgs,
            (draft) => {
              const index = draft.findIndex((wo) => wo._id === workOrder._id);
              if (index !== -1) {
                draft[index] = { ...draft[index], ...workOrder };
              }
            }
          )
        );

        queryFulfilled.catch(({ error }: any) => {
          updateSingleResult.undo();
          updateListResult.undo();
          dispatch(
            setGlobalMessage({
              messageText: `Update failed: ${error?.data?.message}`,
              severity: "error",
              show: true,
            })
          );
        });
      },
      extraOptions: { maxRetries: 0 },
      transformResponse: getResponseBody,
      invalidatesTags: (result) => [
        { type: "InspectionWorkOrders", id: result?.parent?.inspection },
        { type: "AssetWorkOrders", id: result?.asset },
        { type: "ContractWorkOrders", id: result?.contract || '' },
        "OpenHoursByAssetId"
      ],
    }),
    getWorkOrdersBy: builder.query<WebWOQueryResponse[], WorkOrderQuery>({
      query: (params) => {
        const query = getQueryStringFromObject(params);
        return `/workOrder/web/workOrderQuery?${query}`;
      },
      transformResponse: getResponseBody,
      providesTags: [{ type: "WorkOrders", id: "LIST" }],
    }),
    getWorkOrdersByAssetId: builder.query<AssetWorkOrder[],
      { assetId: string; loadInactive?: boolean }
    >({
      query: (params) => `/workOrder/asset?${getQueryStringFromObject(params)}`,
      transformResponse: getResponseBody,
      providesTags: (result, error, { assetId }) => [
        { type: "AssetWorkOrders", id: assetId },
      ],
    }),
    getWorkOrdersByContractId: builder.query<
      WorkOrdersByContractId[],
      { contractId: string }
    >({
      query: ({ contractId }) => `/workOrder/contract/${contractId}`,
      transformResponse: getResponseBody<WorkOrdersByContractId[]>,
      providesTags: (result, error, { contractId }) => [
        { type: "ContractWorkOrders", id: contractId },
      ],
    }),
    getGeneratedWorkOrdersByInspectionId: builder.query<
      WebWOQueryResponse[],
      { inspectionId: string }
    >({
      query: ({ inspectionId }) =>
        `/workOrder/inspectionWorkOrders/${inspectionId}`,
      transformResponse: getResponseBody,
      providesTags: (result, error, { inspectionId }) => [
        { type: "InspectionWorkOrders", id: inspectionId },
      ],
      keepUnusedDataFor: oneHour,
    }),
    updateManyWorkOrders: builder.mutation<
      PopulatedWorkOrder[],
      { workOrders: WorkOrder[] }
    >({
      query({ workOrders }) {
        return {
          url: `/workOrder/updateMany`,
          method: "PATCH",
          body: workOrders,
        };
      },
      transformResponse: getResponseBody,
      invalidatesTags: ["WorkOrders", "ContractWorkOrders", "OpenHoursByAssetId", "AssetWorkOrders"],
      extraOptions: { maxRetries: 0 },
    }),
    getOpenHoursByAssetId: builder.query<Record<string, number>, void>({
      query: () => "/workOrder/openHoursByAsset",
      transformResponse: getResponseBody,
      providesTags: [{ type: "OpenHoursByAssetId", id: "LIST" }],
    }),
    toggleWorkOrderTaskCompletion: builder.mutation<
      WorkOrder,
      { workOrderId: string; taskIndex: number }
    >({
      query({ workOrderId, taskIndex }) {
        return {
          url: `/workOrder/toggleTaskCompletion`,
          method: "PATCH",
          body: { workOrderId, taskIndex },
        };
      },
      transformResponse: getResponseBody,
      extraOptions: {maxRetries: 0},
      onQueryStarted({workOrderId, taskIndex}, { dispatch, queryFulfilled }) {
        const updateSingleResult = dispatch(
          workOrderApi.util.updateQueryData(
            "getWorkOrderById",
            workOrderId,
            (draft) => {
              if (!draft.tasks) return;
              let task = draft.tasks[taskIndex]
              if (task) {
                task.complete = !task.complete;
                draft.tasks[taskIndex] = task;
              }
              return draft
            })
        );
        queryFulfilled.catch(({ error }: any) => {
          updateSingleResult.undo();
          dispatch(
            setGlobalMessage({
              messageText: `Failed to update task: ${error?.data?.message || error?.data || 'Unknown error'}`,
              severity: "error",
              show: true,
            })
          );
      
        });
      },
      
    })
  }),
});

export const {
  useGetWorkOrderByIdQuery,
  useUpdateWorkOrderMutation,
  useCreateWorkOrderMutation,
  useLazyGetWorkOrdersByQuery,
  useGetWorkOrdersByQuery,
  useGetWorkOrdersByAssetIdQuery,
  useGetWorkOrdersByContractIdQuery,
  useUpdateManyWorkOrdersMutation,
  useGetGeneratedWorkOrdersByInspectionIdQuery,
  useGetOpenHoursByAssetIdQuery,
  useToggleWorkOrderTaskCompletionMutation
} = workOrderApi;
  
  